import { AbstractControl, FormControl, Validators, FormArray, FormGroup } from '@angular/forms';
import { User } from './user.schema';
import { Delivery } from './delivery.schema';
import { Vehicle } from './vehicle.schema';
import { phoneValid } from '../helpers/validators/phone.validator';

export class Expert {
    public static validation(vehicles: Vehicle[], profile = false): { [key: string]: AbstractControl } {
        let validator: { [key: string]: AbstractControl } = {
            phone: new FormControl('', Validators.compose([Validators.required, phoneValid()])),
            address: new FormControl('', Validators.required),
            longitude: new FormControl(''),
            latitude: new FormControl(''),
            notes: new FormControl(''),
            vehicles: new FormArray(vehicles.map(x => new FormControl(false))),
        };
        validator['user'] = profile ?
            new FormGroup({ ...User.specificValidation(), ...User.editLoginValidation() }) :
            new FormGroup(User.specificValidation());
        return validator;
    }

    public static readonly validationMessages = {
        'phone': [
            { type: 'required', message: 'Obbligatorio' },
            { type: 'phone', message: 'Formato invalido. Deve contenere fra le 10 e le 14 cifre' }],
        'address': [{ type: 'required', message: 'Obbligatorio' }],
        'user': User.validationMessages
    };

    constructor(
        public id?: number,
        public phone?: string,
        public address?: string,
        public longitude?: string,
        public latitude?: string,
        public notes?: string,
        public vehicles?: Vehicle[],
        public user?: User,
        public deliveries?: Delivery[],
    ) { }
}
