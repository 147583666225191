import { Component, OnInit, ViewChild } from '@angular/core';
import { Delivery, DeliveryStatusAux, DeliveryStatus } from '../../models/delivery.schema';
import { DeliveryService } from '../../services/delivery.service';
import { PaginationComponent } from '../_pagination/pagination.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DeliveryObserverService } from '../../services/observers/delivery-observer.service';
import { AuthStatusService } from '../../services/auth-status.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';
import { DeliveryNotDeliveredDialogComponent } from '../delivery-not-delivered-dialog/delivery-not-delivered-dialog.component';
import { DeliveryExpertFormComponent } from '../delivery-expert-form/delivery-expert-form.component';
import { Expert } from '../../models/expert.schema';
import { ExpertService } from '../../services/expert.service';
//import { format } from 'path';



@Component({
  selector: 'app-deliveries',
  templateUrl: './deliveries.component.html',
  styleUrls: ['./deliveries.component.scss']
})
export class DeliveriesComponent implements OnInit {
  public groups: string[];
  public _deliveryFilterForm;
  public deliveryStatus = DeliveryStatus;
  public deliveryStatusAux = DeliveryStatusAux;
  public deliveries: Delivery[] = [];
  public limit: number;
  public page: number;
  public count: number;
  public maxPage: number;
  public order: {};
  public experts: Expert[];
  public expertDialogOpen: number;
  @ViewChild(PaginationComponent, { static: false }) paginationComponent: PaginationComponent;
  public selected = "active";
  public settings = {
    active: {
      title: "Consegne",
      //subtitle: "In corso"
    },
    inactive: {
      title: "Storico consegne",
      //subtitle: "Storico"
    }
  };
  private readonly tableSpacing = {
    admin: {
      id: 5,
      deliveryDate: 10,
      time: 10,
      partner: 15,
      expert: 10,
      address: 30,
      status: 5,
      detail: 5,
      edit: 5,
      assignExpert: 5,
      historyStatus: 5,
    },
    partner: {
      id: 5,
      deliveryDate: 10,
      time: 10,
      address: 20,
      status: 5,
      detail: 10,
      edit: 10,
      historyStatus: 5,
    },
    expert: {
      id: 5,
      deliveryDate: 10,
      time: 15,
      partner: 10,
      address: 20,
      status: 10,
      edit: 20,
      detail: 10,
      historyStatus: 5,
    },
  };
  public defaultPage = 1;
  public defaultLimit = 100;
  //public defaultOrder = { 'Delivery.id': 'ASC', 'Delivery.deliveryDate': 'DESC', 'Delivery.fromTime': 'DESC', 'Delivery.toTime': 'DESC' };
  public defaultOrder = { 'Delivery.deliveryDate': 'DESC', 'Delivery.fromTime': 'DESC', 'Delivery.toTime': 'DESC' };
  
  
  public filtered = {};
  

  public groupWhitelisted(groups: string | string[]): boolean {
    if (!Array.isArray(groups))
      return this.groups.includes(groups);
    for (let group of groups)
      if (this.groups.includes(group))
        return true;
    return false;
  }

  public getSpacing(property) {
    if (this.groups.includes('expert'))
      return this.tableSpacing['expert'][property] || 0;
    if (this.groups.includes('partner'))
      return this.tableSpacing['partner'][property] || 0;
    if (this.groups.includes('admin'))
      return this.tableSpacing['admin'][property] || 0;
  }

  constructor(
    private authStatusService: AuthStatusService,
    private deliveryService: DeliveryService,
    public deliveryObserverService: DeliveryObserverService,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    public expertService: ExpertService,
  ) {
    this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    // this.deliveryObserverService.object$.subscribe((data) => {
    //   this.deliveries[this.deliveries.findIndex(expert => expert.id == data[0].id)] = data[0];
    //   if (data[1])
    //     this.expertModal(data[1]);
    // });
  }

  
  async ngOnInit() {
    
    var today = new Date();
    var tmptoday = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2); //DATA CORRENTE
    //var navigation = this.route.snapshot.queryParamMap.get('navigation');
    //alert(navigation);

    
    if (this.groupWhitelisted(['admin', 'expert']))
    {   
        this._deliveryFilterForm = new FormGroup({
          'Delivery.id': new FormControl(''),
          'Delivery.deliveryDate_from': new FormControl(tmptoday), //Current Default Date
          'Delivery.deliveryDate_to': new FormControl(tmptoday),   //Current Default Date
          'Delivery.fromTime': new FormControl(''),
          'Delivery.toTime': new FormControl(''),
          'Partner.businessName': new FormControl(''),
          'UserExpert.surname': new FormControl(''),
          //'UserExpert.id': new FormControl(''),
          'Delivery.status': new FormControl(''),
          'Delivery.address': new FormControl(''),
        });
    }
    else // if PARTNER
    {
          this._deliveryFilterForm = new FormGroup({
            'Delivery.id': new FormControl(''),
            'Delivery.deliveryDate_from': new FormControl(''), 
            'Delivery.deliveryDate_to': new FormControl(''),   
            'Delivery.fromTime': new FormControl(''),
            'Delivery.toTime': new FormControl(''),
            'Partner.businessName': new FormControl(''),
            'UserExpert.surname': new FormControl(''),
            //'UserExpert.id': new FormControl(''),
            'Delivery.status': new FormControl(''),
            'Delivery.address': new FormControl(''),
          });
    }
  

    

    this.expertDialogOpen = 0;
    this.selected = !this.route.snapshot.data['active'] ? 'inactive' : 'active';
    //this.loadDeliveries();
    this.filter();
    await this.initialize();
    
    
  }


  async initialize() {
     //if (this.groups.includes('admin')) {
      // try {
      //   this.partners = (await this.partnerService.getAll(undefined, undefined, { 'Partner.businessName': 'ASC' })).partners;
      // }
      // catch { }
      //DG
      try {
        this.experts = (await this.expertService.getAll(undefined, undefined, { 'User.surname': 'ASC', 'User.name': 'ASC' })).experts;
      }
      catch { }
    //}
  }


  async filter() {

    //alert(this._deliveryFilterForm.value);
    //alert('qui');
    
    let filterForm = (new FormGroupToObject()).transform(this._deliveryFilterForm);
    this.filtered = {};

    

    if (filterForm['Delivery.id'])
      this.filtered['Delivery.id'] = filterForm['Delivery.id'];    
    if (filterForm['Delivery.deliveryDate_from'])
      this.filtered['Delivery.deliveryDate'] = { operator: 'moreThanOrEqual', values: [filterForm['Delivery.deliveryDate_from']] };
    if (filterForm['Delivery.deliveryDate_to'])
      this.filtered['Delivery.deliveryDate'] = { operator: 'lessThan', values: [filterForm['Delivery.deliveryDate_to']] };
    if (filterForm['Delivery.deliveryDate_from'] && filterForm['Delivery.deliveryDate_to'])
      this.filtered['Delivery.deliveryDate'] = { operator: 'between', values: [filterForm['Delivery.deliveryDate_from'], filterForm['Delivery.deliveryDate_to']] };
    if (filterForm['Delivery.fromTime'])
      this.filtered['Delivery.fromTime'] = { operator: 'moreThanOrEqual', values: [filterForm['Delivery.fromTime']] };
    if (filterForm['Delivery.toTime'])
      this.filtered['Delivery.toTime'] = { operator: 'lessThan', values: [filterForm['Delivery.toTime']] };
    if (filterForm['Partner.businessName'])
        this.filtered['Partner.businessName'] = { operator: 'like', values: [`%${filterForm['Partner.businessName']}%`] };
      //this.filtered['Partner.businessName'] = filterForm['Partner.businessName'];
    if (filterForm['UserExpert.surname'])
      this.filtered['UserExpert.surname'] = { operator: 'like', values: [`%${filterForm['UserExpert.surname']}%`], concat: [{ string: ' ' }, { column: 'UserExpert.name' }] };
    //DG
    /*
      alert(filterForm['UserExpert.id']);
      if (filterForm['UserExpert.id']){
      this.filtered['UserExpert.id'] = { operator: 'in', values: [filterForm['UserExpert.id']]};
      alert('1');
      alert(filterForm['UserExpert.id']);
    }*/
      if (filterForm['Delivery.status'])
      this.filtered['Delivery.status'] = filterForm['Delivery.status'];
    if (filterForm['Delivery.address'])
       this.filtered['Delivery.address'] = { operator: 'like', values: [`%${filterForm['Delivery.address']}%`]};
      //this.filtered['Delivery.address'] = { operator: 'like', values: [`%${filterForm['Delivery.address']}%`], concat: [{ string: ', ' }, { column: 'Delivery.city' }] };
      
      this.loadDeliveries();
  }

  async fnReset() {
    //this.ngOnInit();
    this._deliveryFilterForm = new FormGroup({
      'Delivery.id': new FormControl(''),
      'Delivery.deliveryDate_from': new FormControl(), 
      'Delivery.deliveryDate_to': new FormControl(),   
      'Delivery.fromTime': new FormControl(''),
      'Delivery.toTime': new FormControl(''),
      'Partner.businessName': new FormControl(''),
      'UserExpert.surname': new FormControl(''),
      'Delivery.status': new FormControl(''),
      'Delivery.address': new FormControl(''),
    });
    this.expertDialogOpen = 0;
    this.selected = !this.route.snapshot.data['active'] ? 'inactive' : 'active';
    //this.loadDeliveries();
    this.filter();
    await this.initialize();
    
  }

  navigate(event) {
    this.loadDeliveries(event.limit, event.page, this.order);
  }

  async loadDeliveries(limit?: number, page?: number, order?: {}) {
    
    this.page = page || parseInt(this.route.snapshot.queryParamMap.get('page')) || this.defaultPage;
    this.limit = limit || parseInt(this.route.snapshot.queryParamMap.get('limit')) || this.defaultLimit;
    this.order = order || JSON.parse(this.route.snapshot.queryParamMap.get('order')) || this.defaultOrder;

    let deliveries = this.selected == 'active' ?
      await this.deliveryService.getAllActive(this.page, this.limit, this.order, this.filtered) :
      await this.deliveryService.getAllInactive(this.page, this.limit, this.order, this.filtered);
    this.deliveries = deliveries.deliveries;
    this.count = deliveries.count;
    this.maxPage = Math.ceil(this.count / this.limit);

  }

  public orderIcon(attribute: string | string[]): string {
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          return 'fa-sort-up';
        case 'DESC':
          return 'fa-sort-down';
        default:
          return 'fa-sort';
      }
    }
  }

  public navigateOrder(attribute: string | string[]) {
    delete this.order['Delivery.updatedAt'];
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          this.order[attr] = 'DESC';
          break;
        case 'DESC':
          this.order[attr] = undefined;
          break;
        default:
          this.order[attr] = 'ASC';
          break;
      }
    }

    this.navigatePage(this.limit, this.page, { order: this.order });
  }

  public expertModal(delivery?: Delivery) {
    if (!this.groups.includes('admin'))
      return;
    let ref = this.dialog.open(DeliveryExpertFormComponent, { width: '80vw', data: { delivery } });
    ref.afterClosed().subscribe(result => {
      if (result?.delivery)
        delivery.expert = result.delivery.expert;
    });
  }

  public async delivered(id: number) {
    if (!confirm("Sei sicuro di voler impostare questa consegna come consegnata?"))
      return;
    return this.deliveryService.setDeliveryDelivered(id).then(data => {
      // Remove delivery that goes into history page
      this.deliveries.splice(this.deliveries.findIndex(delivery => delivery.id == data.id), 1);
      this.snack(`Consegna aggiornata con successo`);
    }).catch(err => {
      this.snack(`Errore nell'aggiornamento della consegna`);
      console.log(err);
    });
  }

  public async notDelivered(id: number) {
    let ref = this.dialog.open(DeliveryNotDeliveredDialogComponent, { width: '80vw', data: { id } });
    ref.afterClosed().subscribe(result => {
      if (result.done)
        this.loadDeliveries();
    });
  }

  public async delivering(id: number) {
    if (!confirm("Sei sicuro di voler impostare questa consegna come in consegna?"))
      return;
    return this.deliveryService.setDeliveryDelivering(id).then(data => {
      // Change delivery status
      this.deliveries.find(delivery => delivery.id == data.id).status = DeliveryStatus.delivering;
      this.snack(`Consegna aggiornata con successo`);
    }).catch(err => {
      this.snack(`Errore nell'aggiornamento della consegna`);
      console.log(err);
    });
  }

  snack(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    });
  }

  navigatePage(limit: number, page: number, args: {} = {}) {
    args = this.escapeQueryStringParams(args);
    const previousArgs = Object.keys(args).length ? {} : this.route.snapshot.queryParams;
    const newParams = { ...previousArgs };
    newParams[`limit`] = limit;
    newParams[`page`] = page;
    // this.loadDeliveries();
    this.router.navigate(this.route.snapshot.url.map(u => u.path), { queryParams: { ...newParams, ...args } }).then(data => {
      this.loadDeliveries();
    });
  }

  getDeliveryLink(id: number, deluxyDelivery: boolean): any[] {

    let route = ['/consegne', id];
    // if ((this.groups.includes('admin') || this.groups.includes('partner')) && this.selected == 'active'){
    //   route.push('edit');
    // } 

     if (this.groups.includes('admin') && this.selected == 'active'){
         route.push('edit');
     } 
     
     if (this.groups.includes('partner') && this.selected == 'active' && !deluxyDelivery)
     {
         route.push('edit');
         
     }

     if (this.groups.includes('partner') && this.selected == 'active' && deluxyDelivery)
     {
         return route;
     }


    return route;
  }

  async export() {
    let res;
    if (this.selected == 'active')
      res = await this.deliveryService.getAllActiveXlsx(this.order, this.filtered);
    else
      res = await this.deliveryService.getAllInactiveXlsx(this.order, this.filtered);
    let anchor = document.createElement('a');
    anchor.download = 'export_deluxy.xlsx';
    anchor.href = (window.webkitURL || window.URL).createObjectURL(res.body);
    anchor.dataset.downloadurl = [res.body.type, anchor.download, anchor.href].join(':');
    anchor.click();
  }

  private escapeQueryStringParams(args: {} = {}): {} {
    Object.keys(args).forEach(key => {
      switch (Object.prototype.toString.call(args[key])) {
        case '[object Number]':
        case '[object String]':
          // Keep the same
          break;
        case '[object Array]':
        case '[object Object]':
          // Convert to JSON
          args[key] = JSON.stringify(args[key]);
          break;
        default:
          // Not supported type
          delete args[key];
          break;
      }
    });
    return args;
  }

}
