import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { AuthStatusService } from '../../../services/auth-status.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginDTO } from '../../../DTO/loginDTO';
import * as jwt from 'jwt-decode';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    loginDTO: LoginDTO;

    constructor(
        private authService: AuthService,
        private authStatusService: AuthStatusService,
        private router: Router,
        private _snackBar: MatSnackBar
    ) {
        this.loginDTO = new LoginDTO();
    }

    ngOnInit() {
        if (this.authStatusService.isAuthenticated)
            this.router.navigate(['/']);
    }

    async login() {
        try {
            this.authService.login(this.loginDTO).then((data: any) => {
                const result: { accessToken: string, refreshToken: string } = data;

                if (result.accessToken) {
                    this.authStatusService.setRefreshStorage(result.refreshToken);
                    this.authStatusService.setAccessStorage(result.accessToken);
                    this._snackBar.open(
                        'Login avvenuto con successo. Reindirizzamento...',
                        'Chiudi',
                        {
                            direction: "ltr",
                            duration: 2000,
                            horizontalPosition: "center",
                            politeness: "assertive",
                            verticalPosition: "top"
                        }).afterDismissed().subscribe(result => {
                            this.router.navigate(['/']);
                        });
                }

            }).catch(error => {
                const result: { statusCode: number, message: string } = error.error;
                let message: string = "";
                switch (result.message) {
                    case 'LOGIN.EMAIL_AND_PASSWORD_REQUIRED':
                        message = "Inserire email e password per poter accedere.";
                        break;
                    case 'LOGIN.USER_NOT_ACTIVE':
                        message = "Utente non attivo. Controllare l'email di attivazione.";
                        break;
                    case 'LOGIN.USER_DISABLED':
                        message = "Utente disattivato. Contattare l'amministratore per ulteriori informazioni.";
                        break;
                    case 'LOGIN.WRONG_USERNAME_OR_PASSWORD':
                        message = "Utente o password non corretti.";
                        break;
                    case 'LOGIN.GROUP_NOT_ASSIGNED':
                        // message = "L'utente non appartiene a nessun gruppo. Impossibile continuare con il login.";
                        message = "Grazie per esserti registrato a Deluxy, il tuo profilo è ora in attesa di approvazione, prova a loggarti più tardi.";
                        break;
                    default:
                        message = "Errore di sistema. Contattare l'amministratore.";
                        break;
                }
                if (message)
                    this._snackBar.open(message, 'Chiudi', {
                        direction: "ltr",
                        duration: 2000,
                        horizontalPosition: "center",
                        politeness: "assertive",
                        verticalPosition: "top"
                    });
            });
        } catch (error) {
            console.log('Server error')
        }
    }
}
