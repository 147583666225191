import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { Transform } from 'class-transformer';
import { fileType } from 'src/app/helpers/validators/file-type.validator';

export class Product {
    public static validation(): { [key: string]: AbstractControl } {
        let validator: { [key: string]: AbstractControl } = {
            name: new FormControl('', Validators.required),
            description: new FormControl(''),
            category: new FormControl(''),
            stock: new FormControl(''),
            line: new FormControl(''),
            price: new FormControl('', Validators.compose([Validators.min(0)])),
            notes: new FormControl(''),
            image: new FormControl(
                undefined,
                fileType(['png', 'jpg', 'gif', 'jpeg'])
            ),
        };
        return validator;
    }

    public static readonly validationMessages = {
        name: [{ type: 'required', message: 'Obbligatorio' }],
        price: [{ type: 'min', message: 'Deve essere positivo' }],
        image: [
            { type: 'fileType', message: 'Formati accettati: png, jpg, gif, jpeg' },
        ],
    };

    constructor(
        public id?: number,
        public name?: string,
        public description?: string,
        price?: number,
        category?: string[],
        line?: string[],
        stock?: string[],
        public image?: any,
        public updatedAt?: Date,
        public createdAt?: Date
    ) {
        this.price = price;
        this.category = category;
        this.line = line;
        this.stock = stock;
    }

    @Transform(toNumber, { toClassOnly: true }) public price?: number;
    @Transform(toArray, { toClassOnly: true }) public category?: string[];
    @Transform(toArray, { toClassOnly: true }) public line?: string[];
    @Transform(toArray, { toClassOnly: true }) public stock?: string[];
}

export function toArray({ value, key, obj, type }) {
    return value ? value.split(';').map(el => el.trim()) : [];
}

function toNumber({ value, key, obj, type }) {
    if ([undefined, null, ''].includes(value))
        return null;
    return Number(value);
}
