import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'durationEnum' })
export class DurationEnumPipe implements PipeTransform {
    transform(value: string): string {
        let matches = value.match(/(\d+)([dmy])/);
        if (matches) {
            let period: number = Number(matches[1]);
            switch (matches[2]) {
                case 'y':
                    return `${period} ${period > 1 ? 'anni' : 'anno'}`;
                case 'm':
                    return `${period} ${period > 1 ? 'mesi' : 'mese'}`;
                case 'd':
                    return `${period} ${period > 1 ? 'giorni' : 'giorno'}`;
            }
        }
    }
}
