import { Component, OnInit, Input } from '@angular/core';
import { Partner } from '../../models/partner.schema';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PartnerService } from '../../services/partner.service';
import { FormGroup } from '@angular/forms';
import { User } from '../../models/user.schema';
import { FormGroupToObject } from '../../pipes/form-group-to-object.pipe';
import { AuthStatusService } from '../../services/auth-status.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-partner-form',
  templateUrl: './partner-form.component.html',
  styleUrls: ['./partner-form.component.scss']
})
export class PartnerFormComponent implements OnInit {
  @Input('profile') public profile: boolean = false;
  public readonly formValidationMsgs;
  public _partnerForm: any;
  public partner: Partner;
  private checkedVatCode: boolean;
  private checkedFiscalCode: boolean;

  private checkedPassword1: boolean;
  private checkedPassword2: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _snackBar: MatSnackBar,
    private partnerService: PartnerService,
    private userService: UserService,
    private authStatusService: AuthStatusService,
  ) {
    this.formValidationMsgs = Partner.validationMessages;
  }

  async ngOnInit() {
    await this.initialize();
  }

  async initialize() {
    this._partnerForm = new FormGroup(Partner.validation(this.profile));
    const partnerId = this.profile ?
      (await this.partnerService.getOne(this.authStatusService.getExtra()['id'])).id.toString() :
      this.route.snapshot.paramMap.get('id');
    if (partnerId) {
      try {
        this.partner = await this.partnerService.getOne(partnerId);
      }
      catch {
        this.router.navigateByUrl("/404", { skipLocationChange: true });
      }
    }
    if (!this.partner) {
      this.partner = new Partner();
      this.partner.user = this.route.snapshot.paramMap.get('userId') ?
        await this.userService.getOne(this.route.snapshot.paramMap.get('userId')) :
        new User();
    }
    this.patch();
    this.setCustomValidators();
    if (this.profile)
      this.setProfileValidators();
  }

  setCustomValidators() {
    this._partnerForm.controls.fiscalCode.valueChanges.subscribe((next: any) => {
      if (!this.checkedFiscalCode) {
        this.checkedFiscalCode = true;
        this._partnerForm.controls.vatCode.updateValueAndValidity();
      }
      else
        this.checkedFiscalCode = false;
    });

    this._partnerForm.controls.vatCode.valueChanges.subscribe((next: any) => {
      if (!this.checkedVatCode) {
        this.checkedVatCode = true;
        this._partnerForm.controls.fiscalCode.updateValueAndValidity();
      }
      else
        this.checkedVatCode = false;
    });
  }

  patch() {
    ['businessName', 'vatCode', 'fiscalCode', 'phone', 'address', 'notes', 'longitude', 'latitude'].forEach(param => {
      this._partnerForm.controls[param].setValue(this.partner[param]);
    });
    ['surname', 'name', 'email'].forEach(param => {
      this._partnerForm.controls.user.controls[param].setValue(this.partner.user[param]);
    });
  }

  setProfileValidators() {
    this.checkedPassword1 = false;
    this.checkedPassword2 = false;

    this._partnerForm.controls.user.controls.password1.valueChanges.subscribe((next: any) => {
      if (!this.checkedPassword1) {
        this.checkedPassword1 = true;
        this._partnerForm.controls.user.controls.password2.updateValueAndValidity();
      }
      else {
        this.checkedPassword1 = false;
        this.checkedPassword2 = false;
      }
    });

    this._partnerForm.controls.user.controls.password2.valueChanges.subscribe((next: any) => {
      if (!this.checkedPassword2) {
        this.checkedPassword2 = true;
        this._partnerForm.controls.user.controls.password1.updateValueAndValidity();
      }
      else {
        this.checkedPassword1 = false;
        this.checkedPassword2 = false;
      }
    });
  }

  onSubmit() {
    let partner = (new FormGroupToObject()).transform(this._partnerForm);
    let promiseResult: any;
    let createType: boolean = true;
    if (this.profile &&
      this._partnerForm.controls.user.value.password1 &&
      this._partnerForm.controls.user.value.password1 === this._partnerForm.controls.user.value.password2
    )
      partner['user']['password'] = this._partnerForm.controls.user.value.password1;
    if (this.partner['id']) {
      partner['user']['id'] = this.partner.user.id;
      promiseResult = this.profile ?
        this.partnerService.updateMe(partner) :
        this.partnerService.update(this.partner['id'], partner);
      createType = false;
    }
    else {
      if (this.partner.user.id)
        partner['user']['id'] = this.partner.user.id;
      promiseResult = this.partnerService.create(partner);
    }
    promiseResult.then((data: Partner) => {
      this.partner = data;
      const message: string = `Partner ${createType ? "creato" : "aggiornato"} con successo`;
      this._snackBar.open(message, 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      }).afterDismissed().subscribe(result => {
        this.authStatusService.isAuthenticated().then(valid => {
          if (!valid)
            this.router.navigate(['/']);
        });
        if (createType) {
          if (this.route.snapshot.paramMap.get('userId'))
            this.router.navigate(['/utenti']);
          else
            this.router.navigate(['/partner', this.partner.id, 'edit']);
        }
      });
      this.patch();
    }).catch(error => {
      this._snackBar.open(`Errore ${createType ? "nella creazione" : "nell'aggiornamento"} del partner`, 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      });
      console.log(error);
    });
  }

  getAddress(place: any) {
    if (!this._partnerForm) return;
    this._partnerForm.controls.address.touched = true;
    this._partnerForm.controls.address.setValue(place.formatted_address || place.name || '');
    this._partnerForm.controls.latitude.setValue(place.geometry?.location?.lat() || '');
    this._partnerForm.controls.longitude.setValue(place.geometry?.location?.lng() || '');
  }

  directions(event: Event) {
    event.preventDefault();
    window.open(`https://www.google.com/maps/dir//${this._partnerForm.controls.address.value}`, '_blank');
  }
}
