import { PipeTransform, Pipe } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'time' })
export class TimePipe extends DatePipe {
    transform(value: Date | string | number, format?: string, timezone?: string, locale?: string): string | null;
    transform(value: null | undefined, format?: string, timezone?: string, locale?: string): null;
    transform(value: Date | string | number | null | undefined, format?: string, timezone?: string, locale?: string): string | null;
    transform(value: any, format: string = "HH:mm:ss"): string | null {
        const date: Date = new Date(`1970-01-01 ${value}`);
        return super.transform(date, format);
    }
}
