import { Component, OnInit } from '@angular/core';
import { FormGroupToObject } from '../../pipes/form-group-to-object.pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OfferService } from '../../services/offer.service';
import { Offer, OfferStatus, OfferStatusAux } from '../../models/offer.schema';
import { Product } from '../../models/product.schema';
import { AuthStatusService } from '../../services/auth-status.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-offer-form',
  templateUrl: './offer-form.component.html',
  styleUrls: ['./offer-form.component.scss']
})
export class OfferFormComponent implements OnInit {
  public groups: string[];
  public readonly formValidationMsgs;
  public _offerForm: any;
  public offer: Offer;
  public offerStatus = OfferStatus;
  public offerStatusAux = OfferStatusAux;
  public products: Product[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authStatusService: AuthStatusService,
    private _snackBar: MatSnackBar,
    private offerService: OfferService,
    private productService: ProductService,
  ) {
    this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    this.formValidationMsgs = Offer.validationMessages;
  }

  async ngOnInit() {
    await this.initialize();
  }

  async initialize() {
    this.products = (await this.productService.getAll(undefined, undefined, { 'Product.name': 'ASC' })).products;
    this._offerForm = new FormGroup(Offer.validation());
    const offerId: string = this.route.snapshot.paramMap.get('id');
    if (offerId) {
      try {
        this.offer = await this.offerService.getOne(Number(offerId));
      }
      catch {
        this.router.navigateByUrl("/404", { skipLocationChange: true });
      }
    }
    if (this.offer)
      this.patch();
    else
      this.offer = new Offer();

    this._offerForm.controls.fromDate.valueChanges.subscribe((next: any) => {
      this._offerForm.controls.toDate.updateValueAndValidity();
    });
  }

  async delete() {
    if (!confirm("Sei sicuro di voler cancellare questa offerta?"))
      return;
    let offer: { id: number | string, success: boolean } = await this.offerService.delete(this.offer.id);
    let message = "Cancellazione dell'offerta fallita.";
    if (offer.success)
      message = "Cancellazione dell'offerta avvenuta con successo.";
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    }).afterDismissed().subscribe(result => {
      if (offer.success)
        this.router.navigate(['/offerte']);
    });
  }

  patch() {
    const control = <FormArray>this._offerForm.get('products');
    control.clear()
    if (!this.offer.products || Array.isArray(this.offer.products) && this.offer.products.length == 0)
      this.offer.products = [new Product()];
    this.offer.products.forEach(f => {
      control.push(this.patchValues(f));
    });
    ['name', 'description', 'timeOffer', 'tryAndBuy', 'fromDate', 'toDate', 'status', 'notes'].forEach(param => {
      this._offerForm.controls[param].setValue(this.offer[param]);
    });
  }

  patchValues(product: Product) {
    let productForm = Offer.productFormGroup();
    if (product && product.id)
      productForm.setValue({ id: product.id });
    return productForm;
  }

  addProduct() {
    const control = <FormArray>this._offerForm.get('products');
    control.push(Offer.productFormGroup());
  }

  removeProduct(index: number) {
    const control = <FormArray>this._offerForm.get('products');
    if (control.length > 1)
      control.removeAt(index);
    else
      control.controls[index].setValue({ id: '' });
  }

  onSubmit() {
    let offer: Offer = (new FormGroupToObject()).transform(this._offerForm);
    offer.timeOffer = !!offer.timeOffer;
    offer.tryAndBuy = !!offer.tryAndBuy;
    offer.products = offer.products.filter(p => !!p.id);
    let promiseResult: any;
    let createType: boolean = true;
    if (this.offer['id']) {
      promiseResult = this.offerService.update(this.offer['id'], offer);
      createType = false;
    }
    else
      promiseResult = this.offerService.create(offer);
    promiseResult.then(async (data: Offer) => {
      let allGood = true;
      this.offer = data;
      if (allGood) {
        const message: string = `Offerta ${createType ? "creata" : "aggiornata"} con successo`;
        this._snackBar.open(message, 'Chiudi', {
          direction: "ltr",
          duration: 2000,
          horizontalPosition: "center",
          politeness: "assertive",
          verticalPosition: "top"
        }).afterDismissed().subscribe(result => {
          if (createType)
            this.router.navigate(['/offerte', this.offer.id]);
        });
        this.patch();
      }
    }).catch(error => {
      this.errorMessage(createType, error);
    });
  }

  errorMessage(createType, error) {
    this._snackBar.open(`Errore ${createType ? 'nella creazione' : "nell'aggiornamento"} della offerta`,
      'Chiudi',
      {
        direction: 'ltr',
        duration: 2000,
        horizontalPosition: 'center',
        politeness: 'assertive',
        verticalPosition: 'top',
      }
    );
    console.log(error);
  }
}
