import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgSelectModule } from '@ng-select/ng-select';
import { JwtModule } from '@auth0/angular-jwt';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BasicAuthInterceptor } from './helpers/basic-auth.interceptor';
import { GroupRestricted } from './helpers/guards/group-restricted.guard';
import { LogOut } from './helpers/guards/log-out.guard';
import { UserLoggedIn } from './helpers/guards/logged-in.guard';
import { CanActivateLogIn } from './helpers/guards/log-in.guard';
import { DeliveryCanActivateLogIn } from './helpers/guards/delivery-log-in.guard';
import { ApiService } from './services/api.service';
import { ApiAuthService } from './services/api-auth.service';
import { AuthStatusService } from './services/auth-status.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
registerLocaleData(localeIt);

import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { ArrayJoinPipe } from './pipes/array-join.pipe';
import { DurationEnumPipe } from './pipes/duration-enum.pipe';
import { FormGroupToObject } from './pipes/form-group-to-object.pipe';
import { TimePipe } from './pipes/time.pipe';
import { ProductImagePipe } from './pipes/product-image.pipe';
import { FancyDatePipe } from './pipes/fancy-date.pipe';
import { RequiredPipe } from './pipes/required-symbol.pipe';
import { ReplacePipe } from './pipes/replace.pipe';

import { ObserverService } from './services/observers/observer.service';
import { DeliveryObserverService } from './services/observers/delivery-observer.service';
import { DeliveryService } from './services/delivery.service';
import { ExpertService } from './services/expert.service';
import { GroupService } from './services/group.service';
import { PartnerService } from './services/partner.service';
import { UserService } from './services/user.service';
import { OfferService } from './services/offer.service';

import { LoginComponent } from './components/auth/login/login.component';
import { NewPasswordFormComponent } from './components/auth/new-password-form/new-password-form.component';
import { RegistrationComponent } from './components/auth/registration/registration.component';
import { ResendActivationComponent } from './components/auth/resend-activation/resend-activation.component';
import { PasswordRecoveryComponent } from './components/auth/password-recovery/password-recovery.component';

import { NavbarComponent } from './components/navbar/navbar.component';
import { DeliveriesComponent } from './components/deliveries/deliveries.component';
import { DeliveryComponent } from './components/delivery/delivery.component';
import { DeliveryFormComponent } from './components/delivery-form/delivery-form.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ErrorHtmlHandlerComponent } from './components/_error-html-handler/error-html-handler.component';
import { PaginationComponent } from './components/_pagination/pagination.component';
import { ExpertsComponent } from './components/experts/experts.component';
import { ExpertFormComponent } from './components/expert-form/expert-form.component';
import { PartnersComponent } from './components/partners/partners.component';
import { PartnerFormComponent } from './components/partner-form/partner-form.component';
import { DeliveryExpertFormComponent } from './components/delivery-expert-form/delivery-expert-form.component';
import { UsersComponent } from './components/users/users.component';
import { PartnerFormDecoratorComponent } from './components/partner-form-decorator/partner-form-decorator.component';
import { ExpertFormDecoratorComponent } from './components/expert-form-decorator/expert-form-decorator.component';
import { AdminFormComponent } from './components/admin-form/admin-form.component';
import { ProfileComponent } from './components/profile/profile.component';
import { OffersComponent } from './components/offers/offers.component';
import { OfferFormComponent } from './components/offer-form/offer-form.component';
import { ProductsComponent } from './components/products/products.component';
import { ProductFormComponent } from './components/product-form/product-form.component';
import { DeliveryLinkDialogComponent } from './components/delivery-link-dialog/delivery-link-dialog.component';
import { DeliveryNotDeliveredDialogComponent } from './components/delivery-not-delivered-dialog/delivery-not-delivered-dialog.component';
import { GoogleMapsSearchComponent } from './components/google-maps-search/google-maps-search.component';

export function tokenGetter() {
  return localStorage.getItem('token');
}

@NgModule({
  declarations: [
    EnumToArrayPipe,
    DateAgoPipe,
    TimePipe,
    ArrayJoinPipe,
    DurationEnumPipe,
    ProductImagePipe,
    FancyDatePipe,
    RequiredPipe,
    ReplacePipe,
    FormGroupToObject,

    LoginComponent,
    NewPasswordFormComponent,
    RegistrationComponent,
    ResendActivationComponent,
    PasswordRecoveryComponent,

    ErrorHtmlHandlerComponent,
    PaginationComponent,
    AppComponent,
    NavbarComponent,

    DeliveriesComponent,
    DeliveryComponent,
    DeliveryFormComponent,
    NotFoundComponent,
    ExpertsComponent,
    ExpertFormComponent,
    PartnersComponent,
    PartnerFormComponent,
    DeliveryExpertFormComponent,
    UsersComponent,
    PartnerFormDecoratorComponent,
    ExpertFormDecoratorComponent,
    AdminFormComponent,
    ProfileComponent,
    OffersComponent,
    OfferFormComponent,
    ProductsComponent,
    ProductFormComponent,
    DeliveryLinkDialogComponent,
    DeliveryNotDeliveredDialogComponent,
    GoogleMapsSearchComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgSelectModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NoopAnimationsModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatDialogModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ["localhost:4200", 'localhost:3000']
      }
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'it-IT' },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    ApiService,
    AuthStatusService,
    ApiAuthService,
    DeliveryService,
    ExpertService,
    ExpertService,
    GroupService,
    PartnerService,
    UserService,
    OfferService,

    ObserverService,
    DeliveryObserverService,
    CanActivateLogIn,
    DeliveryCanActivateLogIn,
    UserLoggedIn,
    LogOut,
    GroupRestricted,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
