import { ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';

export function phoneValid(onlyTouched: boolean = false): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (onlyTouched && !control.touched) return null;
        if (control.value === '' || control.value === null || control.value === undefined) return null;
        let digits = control.value.match(/\d/g);
        let form = control.value.match(/^[- \+\(\)\d]+$/);
        if (!form) return { phone: { condition: 'form' } };
        switch (digits.length) {
            case 10:
                if (control.value.indexOf('+') >= 0)
                    return { phone: { condition: 'form' } };
                return null;
            case 12:
                if (!control.value.match(/^\D*\+\d{2}/))
                    return { phone: { condition: 'international prefix' } };
                return null;
            case 13:
                if (!control.value.match(/^\D*\+\d{3}/))
                    return { phone: { condition: 'international prefix' } };
                return null;
            case 14:
                if (!control.value.match(/^\D*\+?\d{4}/))
                    return { phone: { condition: 'international prefix' } };
                return null;
            default:
                return { phone: { condition: 'form' } };
        }
    }
}
