import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { equalContent } from '../helpers/validators/equal-content.validator';
import { Group } from './group.schema';

export const UserActiveStatus = [
    { value: '1', ln: { it: 'Attivo' } },
    { value: '-1', ln: { it: 'Disattivo' } },
    { value: '0', ln: { it: 'Da convalidare' }, disabled: true },
    { value: null, ln: { it: 'Sconosciuto' }, disabled: true },
]

export class User {
    public static loginValidation(): { [key: string]: AbstractControl } {
        let validator: { [key: string]: AbstractControl } = {
            email: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])),
            password1: new FormControl(''),
            password2: new FormControl('')
        };
        validator['password1'].setValidators([Validators.required, equalContent({ value: [validator['password2']] })]);
        validator['password2'].setValidators([Validators.required, equalContent({ value: [validator['password1']] })]);
        return validator;
    }

    public static editLoginValidation(): { [key: string]: AbstractControl } {
        let validator: { [key: string]: AbstractControl } = {
            password1: new FormControl(''),
            password2: new FormControl('')
        };
        validator['password1'].setValidators(equalContent({ value: [validator['password2']] }));
        validator['password2'].setValidators(equalContent({ value: [validator['password1']] }));
        return validator;
    }

    public static specificValidation(): { [key: string]: AbstractControl } {
        let validator: { [key: string]: AbstractControl } = {
            name: new FormControl('', Validators.required),
            surname: new FormControl('', Validators.required),
            email: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])),
        };
        return validator;
    }

    public static adminValidation(): { [key: string]: AbstractControl } {
        let validator: { [key: string]: AbstractControl } = {
            ...this.loginValidation(),
            // group: new FormControl('', Validators.required),
            active: new FormControl(''),
        };
        return validator;
    }

    public static readonly validationMessages = {
        'name': [{ type: 'required', message: 'Obbligatorio' }],
        'surname': [{ type: 'required', message: 'Obbligatorio' }],
        'email': [{ type: 'required', message: 'Obbligatoria' }, { type: 'pattern', message: 'Formato errato' }],
        'password1': [
            { type: 'required', message: 'Obbligatoria' },
            { type: 'equalContent', message: 'Le password non corrispondono' }],
        'password2': [
            { type: 'required', message: 'Obbligatoria' },
            { type: 'equalContent', message: 'Le password non corrispondono' }]
    };

    constructor(
        public id?: number,
        public email?: string,
        public password?: string,
        public active?: number,
        public emailToken?: string,
        public secretOtp?: string,
        public backupCodes?: string[],
        public createdAt?: Date,
        public updatedAt?: Date,
        public sensibleUpdateAt?: Date,
        public name?: string,
        public surname?: string,
        public extraId?: number,
        public extraType?: string,
        public group?: Group,
    ) { }

    public static translateActive(active: number): string {
        switch (active) {
            case 1:
                return "Attivo";
            case 0:
                return "Da convalidare";
            case -1:
                return "Disattivato";
            case null:
            case undefined:
                return "Da convalidare";
            default:
                return "Sconosciuto";
        }
    }
}
