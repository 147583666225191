import { Component, OnInit, Input } from '@angular/core';
import { Expert } from '../../models/expert.schema';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ExpertService } from '../../services/expert.service';
import { FormGroup } from '@angular/forms';
import { FormGroupToObject } from '../../pipes/form-group-to-object.pipe';
import { Vehicle } from '../../models/vehicle.schema';
import { orderByProperty } from '../../helpers/order-by-property.helper';
import { User } from '../../models/user.schema';
import { AuthStatusService } from '../../services/auth-status.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-expert-form',
  templateUrl: './expert-form.component.html',
  styleUrls: ['./expert-form.component.scss']
})
export class ExpertFormComponent implements OnInit {
  @Input('profile') public profile: boolean = false;
  public readonly formValidationMsgs;
  public _expertForm: any;
  public expert: Expert;
  public vehicles: Vehicle[];

  private checkedPassword1: boolean;
  private checkedPassword2: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _snackBar: MatSnackBar,
    private expertService: ExpertService,
    private userService: UserService,
    private authStatusService: AuthStatusService,
  ) {
    this.formValidationMsgs = Expert.validationMessages;
  }

  async ngOnInit() {
    await this.initialize();
  }

  async initialize() {
    this.vehicles = orderByProperty(await this.expertService.getAllVehicles(), 'name');
    this._expertForm = new FormGroup(Expert.validation(this.vehicles, this.profile));
    const expertId = this.profile ?
      (await this.expertService.getOne(this.authStatusService.getExtra()['id'])).id.toString() :
      this.route.snapshot.paramMap.get('id');
    if (expertId) {
      try {
        this.expert = await this.expertService.getOne(expertId);
      }
      catch {
        this.router.navigateByUrl("/404", { skipLocationChange: true });
      }
    }
    if (!this.expert) {
      this.expert = new Expert();
      this.expert.user = this.route.snapshot.paramMap.get('userId') ?
        await this.userService.getOne(this.route.snapshot.paramMap.get('userId')) :
        new User();
    }
    this.patch();
    if (this.profile)
      this.setProfileValidators();
  }

  patch() {
    ['phone', 'address', 'notes', 'longitude', 'latitude'].forEach(param => {
      this._expertForm.controls[param].setValue(this.expert[param]);
    });
    ['surname', 'name', 'email'].forEach(param => {
      this._expertForm.controls.user.controls[param].setValue(this.expert.user[param]);
    });
    if (this.expert.vehicles)
      for (let i = 0; i < this.vehicles.length; i++) {
        for (const vehicle of this.expert.vehicles)
          if (vehicle.id === this.vehicles[i].id) {
            this._expertForm.controls.vehicles.controls[i].setValue(true);
            break;
          }
      }
  }

  setProfileValidators() {
    this.checkedPassword1 = false;
    this.checkedPassword2 = false;

    this._expertForm.controls.user.controls.password1.valueChanges.subscribe((next: any) => {
      if (!this.checkedPassword1) {
        this.checkedPassword1 = true;
        this._expertForm.controls.user.controls.password2.updateValueAndValidity();
      }
      else {
        this.checkedPassword1 = false;
        this.checkedPassword2 = false;
      }
    });

    this._expertForm.controls.user.controls.password2.valueChanges.subscribe((next: any) => {
      if (!this.checkedPassword2) {
        this.checkedPassword2 = true;
        this._expertForm.controls.user.controls.password1.updateValueAndValidity();
      }
      else {
        this.checkedPassword1 = false;
        this.checkedPassword2 = false;
      }
    });
  }

  onSubmit() {
    let expert = (new FormGroupToObject()).transform(this._expertForm);
    expert['vehicles'] = [];
    for (let i = 0; i < this._expertForm.controls.vehicles.controls.length; i++) {
      if (this._expertForm.controls.vehicles.controls[i].value)
        expert['vehicles'].push(this.vehicles[i]);
    }
    let promiseResult: any;
    let createType: boolean = true;
    if (this.profile &&
      this._expertForm.controls.user.value.password1 &&
      this._expertForm.controls.user.value.password1 === this._expertForm.controls.user.value.password2
    )
      expert['user']['password'] = this._expertForm.controls.user.value.password1;
    if (this.expert['id']) {
      expert['user']['id'] = this.expert.user.id;
      promiseResult = this.profile ?
        this.expertService.updateMe(expert) :
        this.expertService.update(this.expert['id'], expert);
      createType = false;
    }
    else {
      if (this.expert.user.id)
        expert['user']['id'] = this.expert.user.id;
      promiseResult = this.expertService.create(expert);
    }
    promiseResult.then((data: Expert) => {
      this.expert = data;
      const message: string = `Expert ${createType ? "creato" : "aggiornato"} con successo`;
      this._snackBar.open(message, 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      }).afterDismissed().subscribe(result => {
        this.authStatusService.isAuthenticated().then(valid => {
          if (!valid)
            this.router.navigate(['/']);
        });
        if (createType) {
          if (this.route.snapshot.paramMap.get('userId'))
            this.router.navigate(['/utenti']);
          else
            this.router.navigate(['/expert', this.expert.id, 'edit']);
        }
      });
      this.patch();
    }).catch(error => {
      this._snackBar.open(`Errore ${createType ? "nella creazione" : "nell'aggiornamento"} dell'expert`, 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      });
      console.log(error);
    });
  }

  getAddress(place: any) {
    if (!this._expertForm) return;
    this._expertForm.controls.address.touched = true;
    this._expertForm.controls.address.setValue(place.formatted_address || place.name || '');
    this._expertForm.controls.latitude.setValue(place.geometry?.location?.lat() || '');
    this._expertForm.controls.longitude.setValue(place.geometry?.location?.lng() || '');
  }

  directions(event: Event) {
    event.preventDefault();
    window.open(`https://www.google.com/maps/dir//${this._expertForm.controls.address.value}`, '_blank');
  }

}
