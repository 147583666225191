import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup } from '@angular/forms';
import { Delivery, DeliveryStatus } from '../../models/delivery.schema';
import { DeliveryService } from '../../services/delivery.service';
import { Expert } from '../../models/expert.schema';
import { ExpertService } from '../../services/expert.service';
import { FormGroupToObject } from '../../pipes/form-group-to-object.pipe';
import { DeliveryObserverService } from '../../services/observers/delivery-observer.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delivery-expert-form',
  templateUrl: './delivery-expert-form.component.html',
  styleUrls: ['./delivery-expert-form.component.scss']
})
export class DeliveryExpertFormComponent implements OnInit {
  public _deliveryForm: any;
  public delivery: Delivery;
  public experts: Expert[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { delivery: Delivery },
    private deliveryService: DeliveryService,
    private expertService: ExpertService,
    public deliveryObserverService: DeliveryObserverService,
    private _snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<DeliveryExpertFormComponent>,
  ) { }

  async ngOnInit() {
    try {
      this.experts = (await this.expertService.getAll(undefined, undefined, { 'User.surname': 'ASC', 'User.name': 'ASC' })).experts;
    }
    catch { }
    this.initialize();
  }

  async initialize() {
    this.delivery = this.data.delivery;
    this._deliveryForm = new FormGroup(Delivery.validation());
    if (this.delivery && this.delivery.expert)
      this._deliveryForm.controls.expert.controls.id.setValue(this.delivery.expert.id);
  }

  onSubmit() {
    this.delivery.expert = this.experts.find(expert => expert.id == this._deliveryForm.controls.expert.controls.id.value);
    let promiseResult: any;
    promiseResult = this.deliveryService.update(this.delivery['id'], {
      id: this.delivery.id,
      expert: { id: this.delivery.expert.id },
      status: DeliveryStatus.assigned
    });
    promiseResult.then((data: Delivery) => {
      this.delivery = data;
      const message: string = `Expert assegnato con successo`;
      this._snackBar.open(message, 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      });
      this.dialogRef.close({ delivery: this.delivery });
    }).catch(error => {
      this._snackBar.open("Errore nell'assegnazione dell'expert", 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      });
      console.log(error);
    });
  }

}
