import { Pipe, PipeTransform } from '@angular/core';
import { roundNumber } from '../helpers/round-number.helper';

@Pipe({
    name: 'fancyDate',
    pure: true
})
export class FancyDatePipe implements PipeTransform {
    private readonly intervals: {} = {
        'year': { value: 31536000, text: { 1: 'anno', 2: 'anni' } },
        'month': { value: 2592000, text: { 1: 'mese', 2: 'mesi' } },
        'week': { value: 604800, text: { 1: 'settimana', 2: 'settimane' } },
        'day': { value: 86400, text: { 1: 'giorno', 2: 'giorni' } },
        'hour': { value: 3600, text: { 1: 'ora', 2: 'ore' } },
        'minute': { value: 60, text: { 1: 'minuto', 2: 'minuti' } },
        'second': { value: 1, text: { 1: 'secondo', 2: 'secondi' } }
    };

    transform(value: any, precisionLength: number = 2, stopAt?: string): any {
        if (Number(value)) {
            value = Number(value);
            let container = '';
            let curPrecision = 0;
            for (let key of Object.keys(this.intervals)) {
                let rounded = roundNumber(value / this.intervals[key].value, 2);
                if (rounded > 1) {
                    curPrecision++;
                    if (stopAt != key)
                        rounded = roundNumber(rounded, 0, 'f');
                    if (curPrecision < precisionLength)
                        container += `, ${rounded} ${this.getText(key, rounded)}`;
                    else if (curPrecision == precisionLength)
                        container += `${precisionLength == 1 ? '' : ' '}e ${rounded} ${this.getText(key, rounded)}`;
                    else
                        break;
                    if (stopAt == key)
                        break;
                    value -= rounded * this.intervals[key].value;
                }
            }
            return container.substr(2);
        }
        return value;
    }

    private getText(index: string, num: number) {
        let finalText = "";
        for (const count in this.intervals[index].text) {
            if (num < parseInt(count))
                break;
            finalText = this.intervals[index].text[count];
        }
        return finalText;
    }

}
