import { Component, OnInit, ViewChild } from '@angular/core';
import { Product } from '../../models/product.schema';
import { PaginationComponent } from '../_pagination/pagination.component';
import { ProductService } from '../../services/product.service';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthStatusService } from 'src/app/services/auth-status.service';
import { FormControl, FormGroup } from '@angular/forms';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  public products: Product[];
  public limit: number;
  public page: number;
  public count: number;
  public maxPage: number;
  public order: {};
  @ViewChild(PaginationComponent, { static: false }) paginationComponent: PaginationComponent;
  public showElem: string;
  public groups: string[];
  public _productFilterForm;
  public filtered = {};

  constructor(
    private authStatusService: AuthStatusService,
    private productService: ProductService,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
  ) { }

  async ngOnInit() {
    try {
      this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    }
    catch {
      this.groups = [];
    }
    this._productFilterForm = new FormGroup({
      'Product.name': new FormControl(''),
      'Product.category': new FormControl(''),
      'Product.price': new FormControl(''),
      'Product.price.operator': new FormControl('equal'),
      'Product.stock': new FormControl(''),
    });
    this.showElem = 'table';
    this.loadProducts();
  }

  async filter() {
    let filterForm = (new FormGroupToObject()).transform(this._productFilterForm);
    this.filtered = {};
    if (filterForm['Product.name'])
      this.filtered['Product.name'] = { operator: 'like', values: [`%${filterForm['Product.name']}%`] };
    if (filterForm['Product.category'])
      this.filtered['Product.category'] = { operator: 'like', values: [`%${filterForm['Product.category']}%`] };
    if (filterForm['Product.price'])
      this.filtered['Product.price'] = { operator: filterForm['Product.price.operator'], values: [filterForm['Product.price']] };
    if (filterForm['Product.stock'])
      this.filtered['Product.stock'] = { operator: 'like', values: [`%${filterForm['Product.stock']}%`] };
    this.loadProducts();
  }

  navigate(event) {
    this.loadProducts(event.limit, event.page, this.order);
  }

  async loadProducts(limit?: number, page?: number, order?: {}) {
    this.page = page || parseInt(this.route.snapshot.queryParamMap.get('page')) || 1;
    this.limit = limit || parseInt(this.route.snapshot.queryParamMap.get('limit')) || 10;
    this.order = order || JSON.parse(this.route.snapshot.queryParamMap.get('order')) || { 'Product.name': 'ASC' };
    let products = await this.productService.getAll(this.page, this.limit, this.order, this.filtered);
    this.products = products.products;
    this.count = products.count;
    this.maxPage = Math.ceil(this.count / this.limit);
  }

  public orderIcon(attribute: string | string[]): string {
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          return 'fa-sort-up';
        case 'DESC':
          return 'fa-sort-down';
        default:
          return 'fa-sort';
      }
    }
  }

  public navigateOrder(attribute: string | string[]) {
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          this.order[attr] = 'DESC';
          break;
        case 'DESC':
          this.order[attr] = undefined;
          break;
        default:
          this.order[attr] = 'ASC';
          break;
      }
    }
    this.paginationComponent.navigate(this.limit, this.page, { order: this.order });
  }

  public async delete(productId: number) {
    if (!confirm("Sei sicuro di voler cancellare questo prodotto?"))
      return;
    let product: { id: number | string, success: boolean } = await this.productService.delete(productId);
    let message = "Cancellazione del prodotto fallita.";
    if (product.success)
      message = "Cancellazione del prodotto avvenuta con successo.";
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    })
    if (product.success)
      this.loadProducts();
  }

  public show(elem: string) {
    this.showElem = elem;
  }

  openImage(event) {
    let w = window.open("");
    w.document.write(event.target.outerHTML);
  }
}
