import { Injectable } from '@angular/core';
import { ApiAuthService } from './api-auth.service';
import { Partner } from '../models/partner.schema';
import { ToTypeOrm } from '../models/_to-type-orm.schema';

@Injectable({ providedIn: 'root' })
export class PartnerService extends ApiAuthService {
    private readonly url = `${this.baseUrl}/partners`;

    async getAll(page?: number, limit?: number, order?: {}, filter?: { [key: string]: ToTypeOrm }): Promise<{ partners: Partner[], count: number }> {
        let params = {};
        if (page) params['page'] = page;
        if (limit) params['limit'] = limit;
        if (order) params['order'] = JSON.stringify(order);
        if (filter) params['filter'] = JSON.stringify(filter);
        return this.request(() => this.http.get<{ partners: Partner[], count: number }>(`${this.url}`, { params: params }).toPromise());
    }

    async getOne(id: string): Promise<Partner> {
        return this.request(() => this.http.get<Partner>(`${this.url}/${id}`).toPromise());
    }

    async create(partnerParams: Partner): Promise<Partner> {
        let body = partnerParams;
        return this.request(() => this.http.post<Partner>(`${this.url}`, body).toPromise());
    }

    async update(id: number, partnerParams: Partner): Promise<Partner> {
        let body = partnerParams;
        return this.request(() => this.http.put<Partner>(`${this.url}/${id}`, body).toPromise());
    }

    async delete(id: number): Promise<{ id: number, success: boolean }> {
        return this.request(() => this.http.delete<{ id: number, success: boolean }>(`${this.url}/${id}`).toPromise());
    }

    async updateMe(partnerParams: Partner): Promise<Partner> {
        let body = partnerParams;
        return this.request(() => this.http.put<Partner>(`${this.url}`, body).toPromise());
    }
}
