import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { } from 'google.maps';

@Component({
  selector: 'app-google-maps-search',
  templateUrl: './google-maps-search.component.html',
  styleUrls: ['./google-maps-search.component.scss']
})
export class GoogleMapsSearchComponent implements AfterViewInit {
  @Input() addressType: string;
  @Input() initialAddress: string = '';
  @Input() control: FormControl;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('addressText') addressText: any;

  constructor() { }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.addressText.nativeElement,
      {
        componentRestrictions: { country: ['it', 'sm', 'va', 'mc', 'ch', 'at', 'si'] },
        types: [this.addressType]  // 'establishment' / 'address' / 'geocode'
      });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      this.invokeEvent(place);
    });
  }

  focusOut() {
    this.setAddress.emit({ name: this.addressText.nativeElement.value });
  }

  invokeEvent(place: google.maps.places.PlaceResult) {
    this.addressText.nativeElement.value = place.formatted_address;
    this.setAddress.emit(place);
  }

}
