import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginDTO } from '../../../DTO/loginDTO';

@Component({
  selector: 'app-resend-activation',
  templateUrl: './resend-activation.component.html',
  styleUrls: ['./resend-activation.component.scss']
})
export class ResendActivationComponent implements OnInit {
  public loginDTO: LoginDTO;

  constructor(private authService: AuthService, private route: ActivatedRoute, private router: Router, private _snackBar: MatSnackBar) {
    this.loginDTO = new LoginDTO();
  }

  ngOnInit() {
  }

  async resend() {
    try {
      this.authService.sendActivation(this.loginDTO.email).then((data: any) => {
        const result: { message: string } = data;

        if (result.message === "REGISTRATION.SENT_EMAIL") {
          this._snackBar.open(
            "Email per l'attivazione dell'account inviata correttamente. Controlla la tua casella di posta per continuare la procedura.",
            'Chiudi',
            {
              direction: "ltr",
              duration: 5000,
              horizontalPosition: "center",
              politeness: "assertive",
              verticalPosition: "top"
            });
        }

      }).catch(error => {
        const result: { statusCode: number, message: string } = error.error;
        let message: string = "";
        switch (result.message) {
          case 'REGISTRATION.USER_NOT_EXISTS':
            message = "L'email non esiste.";
            break;
          case 'REGISTRATION.MAIL_NOT_SENT':
            message = "Non è stato possibile inviare l'email. Contattare l'amministratore.";
            break;
          case 'REGISTRATION.EMAIL_EXISTS':
          default:
            message = "Errore di sistema. Contattare l'amministratore.";
            break;
        }
        if (message)
          this._snackBar.open(message, 'Chiudi', {
            direction: "ltr",
            duration: 2000,
            horizontalPosition: "center",
            politeness: "assertive",
            verticalPosition: "top"
          });
      });
    } catch (error) {
      console.log('Server error')
    }
  }
}
