import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, Validators, FormGroup } from '@angular/forms';
import { equalContent } from '../../../helpers/validators/equal-content.validator';
import { AuthService } from '../../../services/auth.service';
import { AuthStatusService } from '../../../services/auth-status.service';

@Component({
  selector: 'app-new-password-form',
  templateUrl: './new-password-form.component.html',
  styleUrls: ['./new-password-form.component.scss']
})
export class NewPasswordFormComponent implements OnInit {
  public readonly formValidationMsgs;
  private checkedPassword1: boolean;
  private checkedPassword2: boolean;
  public _newPasswordForm: FormGroup;
  @Input('token') public token: string;
  @Input('activate') public activate: boolean = false;

  @Input('newPasswordForm') set newPasswordForm(value: FormGroup) { this._newPasswordForm = value; }
  get newPasswordForm(): FormGroup { return this._newPasswordForm; }

  constructor(private authStatusService: AuthStatusService, private authService: AuthService) {
    this.formValidationMsgs = {
      'password1': [
        { type: 'required', message: 'Password obbligatoria' },
        { type: 'equalContent', message: 'Le password non corrispondono' }],
      'password2': [
        { type: 'required', message: 'Password obbligatoria' },
        { type: 'equalContent', message: 'Le password non corrispondono' }]
    };
    this.newPasswordForm = new FormGroup(this.validation());
  }

  ngOnInit() {
    this.checkedPassword1 = false;
    this.checkedPassword2 = false;

    this.newPasswordForm.controls.password1.valueChanges.subscribe((next: any) => {
      if (!this.checkedPassword1) {
        this.checkedPassword1 = true;
        this.newPasswordForm.controls.password2.updateValueAndValidity();
      }
      else {
        this.checkedPassword1 = false;
        this.checkedPassword2 = false;
      }
    });

    this.newPasswordForm.controls.password2.valueChanges.subscribe((next: any) => {
      if (!this.checkedPassword2) {
        this.checkedPassword2 = true;
        this.newPasswordForm.controls.password1.updateValueAndValidity();
      }
      else {
        this.checkedPassword1 = false;
        this.checkedPassword2 = false;
      }
    });
  }

  private validation(): { [key: string]: AbstractControl } {
    let validator: { [key: string]: AbstractControl } = {
      password1: new FormControl(''),
      password2: new FormControl('')
    };
    validator['password1'].setValidators([Validators.required, equalContent({ value: [validator['password2']] })]);
    validator['password2'].setValidators([Validators.required, equalContent({ value: [validator['password1']] })]);
    return validator;
  }

  async setNewPassword(activate = false): Promise<Object> {
    if (this.token)
      return this.authService.postNewPassword(this.token, this._newPasswordForm.value.password1, this._newPasswordForm.value.password2, activate);
    else if (this.authStatusService.isAuthenticated())
      return this.authService.newPassword(this._newPasswordForm.value.password1, this._newPasswordForm.value.password2);
  }
}
