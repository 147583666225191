import { Injectable } from '@angular/core';
import { ApiAuthService } from './api-auth.service';
import { Product } from '../models/product.schema';
import { GetAllFilter } from '../models/_to-type-orm.schema';
import { HttpResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class ProductService extends ApiAuthService {
  private readonly url = `${this.baseUrl}/products`;

  getAll(page?: number, limit?: number, order?: {}, filter?: GetAllFilter): Promise<{ products: Product[]; count: number }> {
    let params = {};
    if (page) params['page'] = page;
    if (limit) params['limit'] = limit;
    if (order) params['order'] = JSON.stringify(order);
    if (filter) params['filter'] = JSON.stringify(filter);
    return this.request(() =>
      this.http.get<{ products: Product[]; count: number }>(`${this.url}`, { params: params, }).toPromise()
    );
  }

  getOne(id: number): Promise<Product> {
    return this.request(() =>
      this.http.get<Product>(`${this.url}/${id}`).toPromise()
    );
  }

  create(productParams: Product): Promise<Product> {
    let body = productParams;
    return this.request(() =>
      this.http.post<Product>(`${this.url}`, body).toPromise()
    );
  }

  update(id: number, productParams: Product): Promise<Product> {
    let body = productParams;
    return this.request(() =>
      this.http.put<Product>(`${this.url}/${id}`, body).toPromise()
    );
  }

  delete(id: number): Promise<{ id: number; success: boolean }> {
    return this.request(() =>
      this.http.delete<{ id: number; success: boolean }>(`${this.url}/${id}`).toPromise()
    );
  }

  findOneImage(id: number): Promise<HttpResponse<Blob>> {
    return this.request(() =>
      this.http
        .get(`${this.url}/img/${id}`, {
          observe: 'response',
          responseType: 'blob',
        })
        .toPromise()
    );
  }

  updateProductPhoto(id: number, file: any) {
    const formData = new FormData();
    formData.append('image', file);
    console.log('before put', file);
    return this.request(() =>
      this.http.put<Product>(`${this.url}/img/${id}`, formData).toPromise()
    );
  }
}
