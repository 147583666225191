import { Component, OnInit } from '@angular/core';
import { FormGroupToObject } from '../../pipes/form-group-to-object.pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductService } from '../../services/product.service';
import { Product } from '../../models/product.schema';
import { PartnerService } from '../../services/partner.service';
import { ExpertService } from '../../services/expert.service';
import { AuthStatusService } from '../../services/auth-status.service';
import { plainToClass } from 'class-transformer';

@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss']
})
export class ProductFormComponent implements OnInit {
  public groups: string[];
  public readonly formValidationMsgs;
  public _productForm: any;
  public product: Product;
  public removeFile: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authStatusService: AuthStatusService,
    private _snackBar: MatSnackBar,
    private productService: ProductService,
  ) {
    this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    this.formValidationMsgs = Product.validationMessages;
  }

  async ngOnInit() {
    try {
      this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    }
    catch {
      this.groups = [];
    }
    await this.initialize();
  }

  async initialize() {
    this._productForm = new FormGroup(Product.validation());
    const productId: string = this.route.snapshot.paramMap.get('id');
    if (productId) {
      try {
        this.product = await this.productService.getOne(Number(productId));
      }
      catch {
        this.router.navigateByUrl("/404", { skipLocationChange: true });
      }
    }
    if (this.product)
      this.patch();
    else
      this.product = new Product();
  }

  async delete() {
    if (!confirm("Sei sicuro di voler cancellare questo prodotto?"))
      return;
    let product: { id: number | string, success: boolean } = await this.productService.delete(this.product.id);
    let message = "Cancellazione del prodotto fallita.";
    if (product.success)
      message = "Cancellazione del prodotto avvenuta con successo.";
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    }).afterDismissed().subscribe(result => {
      if (product.success)
        this.router.navigate(['/prodotti']);
    });
  }

  patch() {
    this.removeFile = false;
    ['name', 'description', 'price'].forEach(param => {
      this._productForm.controls[param].setValue(this.product[param]);
    });
    if (this.product.category)
      this._productForm.controls.category.setValue(this.product.category.join('; '));
    if (this.product.stock)
      this._productForm.controls.stock.setValue(this.product.stock.join('; '));
    if (this.product.line)
      this._productForm.controls.line.setValue(this.product.line.join('; '));
  }

  onSubmit() {
    let product: Product = new FormGroupToObject().transform(this._productForm);
    let image = product.image;
    if (!this.removeFile) delete product.image;
    product = plainToClass(Product, product);
    let promiseResult: any;
    let createType: boolean = true;
    if (this.product['id']) {
      promiseResult = this.productService.update(this.product['id'], product);
      createType = false;
    }
    else
      promiseResult = this.productService.create(product);
    promiseResult
      .then(async (data: Product) => {
        let allGood = true;
        this.product = await this.productService.getOne(data.id);
        if (image)
          this.product = await this.uploadImage(this.product.id).catch((err) => {
            this.errorMessage(createType, err);
            allGood = false;
          });
        if (allGood) {
          const message: string = `Prodotto ${createType ? 'creato' : 'aggiornato'} con successo`;
          this._snackBar.open(message, 'Chiudi', {
            direction: 'ltr',
            duration: 2000,
            horizontalPosition: 'center',
            politeness: 'assertive',
            verticalPosition: 'top',
          }).afterDismissed().subscribe(result => {
            if (createType)
              this.router.navigate(['/prodotti', this.product.id]);
          });
          this.patch();
        }
      })
      .catch((error) => {
        this.errorMessage(createType, error);
      });
  }

  errorMessage(createType, error) {
    let message = `Errore ${createType ? 'nella creazione' : "nell'aggiornamento"} del prodotto`;
    if (error.error && error.error.message === 'DUPLICATE.PRODUCT')
      message = `Errore: esiste già un prodotto con questo nome!`;
    this._snackBar.open(message,
      'Chiudi',
      {
        direction: 'ltr',
        duration: 2000,
        horizontalPosition: 'center',
        politeness: 'assertive',
        verticalPosition: 'top',
      }
    );
    console.log(error);
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this._productForm.get('image').setValue(file);
    }
  }

  uploadImage(productId: number) {
    return this.productService.updateProductPhoto(
      productId,
      this._productForm.get('image').value
    );
  }

  deleteFile() {
    this.removeFile = true;
  }

  openImage(event) {
    let w = window.open("");
    w.document.write(event.target.outerHTML);
  }
}
