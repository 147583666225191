import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { PartnerFormComponent } from '../partner-form/partner-form.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { PartnerService } from '../../services/partner.service';

@Component({
  selector: 'app-partner-form-decorator',
  templateUrl: './partner-form-decorator.component.html',
  styleUrls: ['./partner-form-decorator.component.scss']
})
export class PartnerFormDecoratorComponent implements OnInit {
  @ViewChild(PartnerFormComponent, { static: false }) form: PartnerFormComponent;

  constructor(
    private router: Router,
    private partnerService: PartnerService,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
  }

  async delete() {
    if (!confirm("Sei sicuro di voler cancellare questo partner?"))
      return;
    let partner: { id: number | string, success: boolean } = await this.partnerService.delete(this.form.partner.id);
    let message = "Cancellazione del partner fallita.";
    if (partner.success)
      message = "Cancellazione del partner avvenuta con successo.";
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    }).afterDismissed().subscribe(result => {
      if (partner.success)
        this.router.navigate(['/partner']);
    });
  }

}
