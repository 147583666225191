import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'arrayJoin' })
export class ArrayJoinPipe implements PipeTransform {
    transform(value, character: string = "\n", selector: any = null, selectorGlue: string = " "): string {
        if (!value || !value.length)
            return undefined;
        if (selector) {
            value = value.map(el => {
                if (Array.isArray(selector)) {
                    let compressed = "";
                    for (let sel of selector)
                        compressed += selectorGlue + el[sel];
                    return compressed.substr(selectorGlue.length);
                }
                return el[selector];
            });
        }
        return value.join(character);
    }
}
