import { ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
import { isRequired } from '../control-is-required.helper';

export function moreThan(element: any, onlyTouched: boolean = false, customKey?: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if ((!control.value && !isRequired(control)) || (onlyTouched && !control.touched && !control['_pendingChange']))
            return null;
        let comparisonValue = castValue(element);
        let value = castValue(control.value, element);
        if ((!comparisonValue && comparisonValue !== 0) || value > comparisonValue)
            return null;
        return { [customKey ? customKey : 'moreThan']: { condition: value } };
    }
}

export function lessThan(element: any, onlyTouched: boolean = false, customKey?: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if ((!control.value && !isRequired(control)) || (onlyTouched && !control.touched && !control['_pendingChange']))
            return null;
        let comparisonValue = castValue(element);
        let value = castValue(control.value, element);
        if ((!comparisonValue && comparisonValue !== 0) || value < comparisonValue)
            return null;
        return { [customKey ? customKey : 'lessThan']: { condition: value } };
    }
}

export function moreThanOrEqual(element: any, onlyTouched: boolean = false, customKey?: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if ((!control.value && !isRequired(control)) || (onlyTouched && !control.touched && !control['_pendingChange']))
            return null;
        let comparisonValue = castValue(element);
        let value = castValue(control.value, element);
        if ((!comparisonValue && comparisonValue !== 0) || value >= comparisonValue)
            return null;
        return { [customKey ? customKey : 'moreThanOrEqual']: { condition: value } };
    }
}

export function lessThanOrEqual(element: any, onlyTouched: boolean = false, customKey?: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if ((!control.value && !isRequired(control)) || (onlyTouched && !control.touched && !control['_pendingChange']))
            return null;
        let comparisonValue = castValue(element);
        let value = castValue(control.value, element);
        if ((!comparisonValue && comparisonValue !== 0) || value <= comparisonValue)
            return null;
        return { [customKey ? customKey : 'lessThanOrEqual']: { condition: value } };
    }
}

function castValue(element: any, otherElement?: any) {
    if (element === undefined || element === null)
        return element;
    if (element.value !== undefined)
        element = element.value;
    if (otherElement !== undefined) {
        switch (typeof otherElement) {
            case 'object':
                switch (otherElement.constructor.name) {
                    case 'Date':
                        if (!(element instanceof Date)) return new Date(element);
                        break;
                    case 'Number':
                        return Number(element);
                    case 'String':
                        return element.toString();
                }
                break;
            case 'number':
                return Number(element);
            case 'string':
                return element.toString();
        }
    }
    return element;
}
