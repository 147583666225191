import { Component, OnInit, ViewChild } from '@angular/core';
import { Offer, OfferStatusAux, OfferStatus } from '../../models/offer.schema';
import { OfferService } from '../../services/offer.service';
import { PaginationComponent } from '../_pagination/pagination.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthStatusService } from '../../services/auth-status.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, FormGroup } from '@angular/forms';
import { FormGroupToObject } from 'src/app/pipes/form-group-to-object.pipe';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.scss']
})
export class OffersComponent implements OnInit {
  public groups: string[];
  public offerStatus = OfferStatus;
  public offerStatusAux = OfferStatusAux;
  public offers: Offer[] = [];
  public limit: number;
  public page: number;
  public count: number;
  public maxPage: number;
  public order: {};
  public expertDialogOpen: number;
  @ViewChild(PaginationComponent, { static: false }) paginationComponent: PaginationComponent;
  public selected = "active";
  public settings = {
    active: {
      title: "Offerte",
    },
  };
  private readonly tableSpacing = {
    admin: {
      id: 5,
      name: 15,
      description: 20,
      products: 15,
      fromDate: 10,
      toDate: 10,
      status: 5,
      edit: 20,
    },
    partner: {
      id: 5,
      name: 15,
      description: 20,
      products: 15,
      fromDate: 10,
      toDate: 10,
      status: 5,
      edit: 20,
    },
    expert: {
      id: 5,
      name: 15,
      description: 20,
      products: 15,
      fromDate: 10,
      toDate: 10,
      status: 5,
      edit: 20,
    },
  };
  public _offerFilterForm;
  public filtered = {};

  public getSpacing(property) {
    if (this.groups.includes('expert'))
      return this.tableSpacing['expert'][property] || 0;
    if (this.groups.includes('partner'))
      return this.tableSpacing['partner'][property] || 0;
    if (this.groups.includes('admin'))
      return this.tableSpacing['admin'][property] || 0;
  }

  constructor(
    private authStatusService: AuthStatusService,
    private offerService: OfferService,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
  }

  async ngOnInit() {
    this._offerFilterForm = new FormGroup({
      'Offer.name': new FormControl(''),
      'Offer.description': new FormControl(''),
      'Product.name': new FormControl(''),
      'Offer.fromDate_from': new FormControl(''),
      'Offer.fromDate_to': new FormControl(''),
      'Offer.toDate_from': new FormControl(''),
      'Offer.toDate_to': new FormControl(''),
      'Offer.status': new FormControl(''),
    });
    this.expertDialogOpen = 0;
    this.selected = !this.route.snapshot.data['active'] ? 'inactive' : 'active';
    this.loadOffers();
  }

  async filter() {
    let filterForm = (new FormGroupToObject()).transform(this._offerFilterForm);
    this.filtered = {};
    if (filterForm['Offer.fromDate_from'])
      this.filtered['Offer.fromDate'] = { operator: 'moreThanOrEqual', values: [filterForm['Offer.fromDate_from']] };
    if (filterForm['Offer.fromDate_to'])
      this.filtered['Offer.fromDate'] = { operator: 'lessThan', values: [filterForm['Offer.fromDate_to']] };
    if (filterForm['Offer.fromDate_from'] && filterForm['Offer.fromDate_to'])
      this.filtered['Offer.fromDate'] = { operator: 'between', values: [filterForm['Offer.fromDate_from'], filterForm['Offer.fromDate_to']] };
    if (filterForm['Offer.toDate_from'])
      this.filtered['Offer.toDate'] = { operator: 'moreThanOrEqual', values: [filterForm['Offer.toDate_from']] };
    if (filterForm['Offer.toDate_to'])
      this.filtered['Offer.toDate'] = { operator: 'lessThan', values: [filterForm['Offer.toDate_to']] };
    if (filterForm['Offer.toDate_from'] && filterForm['Offer.toDate_to'])
      this.filtered['Offer.toDate'] = { operator: 'between', values: [filterForm['Offer.toDate_from'], filterForm['Offer.toDate_to']] };
    if (filterForm['Offer.status'])
      this.filtered['Offer.status'] = filterForm['Offer.status'];
    if (filterForm['Offer.name'])
      this.filtered['Offer.name'] = { operator: 'like', values: [`%${filterForm['Offer.name']}%`] };
    if (filterForm['Offer.description'])
      this.filtered['Offer.description'] = { operator: 'like', values: [`%${filterForm['Offer.description']}%`] };
    if (filterForm['Product.name'])
      this.filtered['Product.name'] = { operator: 'like', values: [`%${filterForm['Product.name']}%`] };
    this.loadOffers();
  }

  navigate(event) {
    this.loadOffers(event.limit, event.page, this.order);
  }

  async loadOffers(limit?: number, page?: number, order?: {}) {
    this.page = page || parseInt(this.route.snapshot.queryParamMap.get('page')) || 1;
    this.limit = limit || parseInt(this.route.snapshot.queryParamMap.get('limit')) || 10;
    this.order = order || JSON.parse(this.route.snapshot.queryParamMap.get('order')) || { 'Offer.fromDate': 'DESC' };
    let offers = await this.offerService.getAll(this.page, this.limit, this.order, this.filtered);
    this.offers = offers.offers;
    this.count = offers.count;
    this.maxPage = Math.ceil(this.count / this.limit);
  }

  public orderIcon(attribute: string | string[]): string {
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          return 'fa-sort-up';
        case 'DESC':
          return 'fa-sort-down';
        default:
          return 'fa-sort';
      }
    }
  }

  public navigateOrder(attribute: string | string[]) {
    delete this.order['Offer.updatedAt'];
    attribute = Array.isArray(attribute) ? attribute : [attribute];
    for (let attr of attribute) {
      switch (this.order[attr]) {
        case 'ASC':
          this.order[attr] = 'DESC';
          break;
        case 'DESC':
          this.order[attr] = undefined;
          break;
        default:
          this.order[attr] = 'ASC';
          break;
      }
    }
    this.navigatePage(this.limit, this.page, { order: this.order });
  }

  navigatePage(limit: number, page: number, args: {} = {}) {
    args = this.escapeQueryStringParams(args);
    const previousArgs = Object.keys(args).length ? {} : this.route.snapshot.queryParams;
    const newParams = { ...previousArgs };
    newParams[`limit`] = limit;
    newParams[`page`] = page;
    // this.loadOffers();
    this.router.navigate(this.route.snapshot.url.map(u => u.path), { queryParams: { ...newParams, ...args } }).then(data => {
      this.loadOffers();
    });
  }

  getOfferLink(id: number): any[] {
    let route = ['/consegne', id];
    if ((this.groups.includes('admin') || this.groups.includes('partner')) && this.selected == 'active')
      route.push('edit');
    return route;
  }

  private escapeQueryStringParams(args: {} = {}): {} {
    Object.keys(args).forEach(key => {
      switch (Object.prototype.toString.call(args[key])) {
        case '[object Number]':
        case '[object String]':
          // Keep the same
          break;
        case '[object Array]':
        case '[object Object]':
          // Convert to JSON
          args[key] = JSON.stringify(args[key]);
          break;
        default:
          // Not supported type
          delete args[key];
          break;
      }
    });
    return args;
  }

  public async publish(id: number) {
    if (!confirm("Sei sicuro di voler pubblicare questa offerta?"))
      return;
    return this.update({ id, status: OfferStatus.published, fromDate: new Date() }, `Offerta pubblicata con successo`);
  }

  public async finish(id: number) {
    if (!confirm("Sei sicuro di voler completare questa offerta?"))
      return;
    return this.update({ id, status: OfferStatus.finished, toDate: new Date() }, `Offerta terminata con successo`);
  }

  async delete(id: number) {
    if (!confirm("Sei sicuro di voler cancellare questa offerta?"))
      return;
    let offer: { id: number | string, success: boolean } = await this.offerService.delete(id);
    let message = "Cancellazione dell'offerta fallita.";
    if (offer.success) {
      message = "Cancellazione dell'offerta avvenuta con successo.";
      this.loadOffers();
    }
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    });
  }

  public async update(offer: Offer, message: string) {
    this.offerService.update(offer.id, offer).then((data: Offer) => {
      this.loadOffers();
      this._snackBar.open(message, 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      });
    }).catch(error => {
      this._snackBar.open("Errore nell'aggiornamento dell'offerta", 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      });
      console.log(error);
    });
  }

}
