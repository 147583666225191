import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthStatusService } from './auth-status.service';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class ApiAuthService extends ApiService {
  constructor(
    protected readonly http: HttpClient,
    protected authStatusService: AuthStatusService,
  ) {
    super(http);
  }

  async request(func) {
    try {
      return await func();
    }
    catch (ex) {
      if (ex.status != 401) throw ex;
      // Manage unauthorized by expired access token and requesting new
      const authenticated: boolean = await this.authStatusService.isAuthenticated();
      if (!authenticated) throw new Error('UNAUTHORIZED');
      return func();
    }
  }
}
