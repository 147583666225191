import { AbstractControl, FormControl, Validators, FormArray, FormGroup } from '@angular/forms';
import { User } from './user.schema';
import { Delivery } from './delivery.schema';
import { requiredIfAllEmpty } from '../helpers/validators/required-if-all-empty.validator';
import { phoneValid } from '../helpers/validators/phone.validator';

export class Partner {
    public static validation(profile = false): { [key: string]: AbstractControl } {
        let validator: { [key: string]: AbstractControl } = {
            businessName: new FormControl('', Validators.required),
            vatCode: new FormControl(''),
            fiscalCode: new FormControl(''),
            phone: new FormControl('', [Validators.required, phoneValid()]),
            address: new FormControl('', Validators.required),
            longitude: new FormControl(''),
            latitude: new FormControl(''),
            notes: new FormControl(''),
        };
        validator['user'] = profile ?
            new FormGroup({ ...User.specificValidation(), ...User.editLoginValidation() }) :
            new FormGroup(User.specificValidation());
        validator['vatCode'].setValidators([
            Validators.pattern(/^\d{11}$/),
            requiredIfAllEmpty({ value: [validator['fiscalCode']] })]);
        validator['fiscalCode'].setValidators([
            Validators.pattern(/^\w{6}\d{2}\w\d{2}\w\d{3}\w$/),
            requiredIfAllEmpty({ value: [validator['vatCode']] })]);
        return validator;
    }

    public static readonly validationMessages = {
        'businessName': [{ type: 'required', message: 'Obbligatorio' }],
        'phone': [
            { type: 'required', message: 'Obbligatorio' },
            { type: 'pattern', message: 'Formato errato' },
            { type: 'phone', message: 'Formato invalido. Deve contenere fra le 10 e le 14 cifre' }],
        'address': [{ type: 'required', message: 'Obbligatorio' }],
        'user': User.validationMessages,
        'vatCode': [
            { type: 'required', message: 'Obbligatoria' },
            { type: 'pattern', message: 'Formato errato' },
            { type: 'requiredIfAllEmpty', message: 'Codice fiscale o partita iva obbligatori' }],
        'fiscalCode': [
            { type: 'required', message: 'Obbligatorio' },
            { type: 'pattern', message: 'Formato errato' },
            { type: 'requiredIfAllEmpty', message: 'Codice fiscale o partita iva obbligatori' }],
    };

    constructor(
        public id?: number,
        public businessName?: string,
        public vatCode?: string,
        public fiscalCode?: string,
        public phone?: string,
        public address?: string,
        public longitude?: string,
        public latitude?: string,
        public notes?: string,
        public user?: User,
        public deliveries?: Delivery[],
    ) { }
}
