export function orderByProperty(array: any[], property: any, reverse: boolean = false) {
    return array ? array
        .sort((a, b) => {
            if (!Array.isArray(property)) {
                a = a[property];
                b = b[property];
            }
            else {
                for (let prop of property) {
                    a = a[prop];
                    b = b[prop];
                }
            }
            if (reverse)
                return a < b ? 1 : a > b ? -1 : 0;
            return a < b ? -1 : a > b ? 1 : 0;
        })
        : array;
}
