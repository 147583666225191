import { Injectable } from '@angular/core';
import { ApiAuthService } from './api-auth.service';
import { Group } from '../models/group.schema';

@Injectable({ providedIn: 'root' })
export class GroupService extends ApiAuthService {
    private readonly url = `${this.baseUrl}/groups`;

    async getAll(page?: number, limit?: number, order?: {}): Promise<{ groups: Group[], count: number }> {
        let params = {};
        if (page) params['page'] = page;
        if (limit) params['limit'] = limit;
        if (order) params['order'] = JSON.stringify(order);
        return this.request(() => this.http.get<{ groups: Group[], count: number }>(`${this.url}`, { params: params }).toPromise());
    }
}
