import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup } from '@angular/forms';
import { User } from '../../models/user.schema';
import { UserService } from '../../services/user.service';
import { FormGroupToObject } from '../../pipes/form-group-to-object.pipe';
import { AuthStatusService } from '../../services/auth-status.service';

@Component({
  selector: 'app-admin-form',
  templateUrl: './admin-form.component.html',
  styleUrls: ['./admin-form.component.scss']
})
export class AdminFormComponent implements OnInit {
  @Input('profile') public profile: boolean = false;
  public readonly formValidationMsgs;
  public _userForm: any;
  public user: User;

  private checkedPassword1: boolean;
  private checkedPassword2: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _snackBar: MatSnackBar,
    private userService: UserService,
    private authStatusService: AuthStatusService,
  ) {
    this.formValidationMsgs = User.validationMessages;
  }

  async ngOnInit() {
    await this.initialize();
  }

  async initialize() {
    this._userForm = new FormGroup({ ...User.specificValidation(), ...User.editLoginValidation() });
    const userId = this.profile ? this.authStatusService.getUserId() : this.route.snapshot.paramMap.get('id');
    if (userId) {
      try {
        this.user = await this.userService.getOne(userId);
      }
      catch {
        this.router.navigateByUrl("/404", { skipLocationChange: true });
      }
    }
    if (this.user)
      this.patch();
    if (this.profile)
      this.setProfileValidators();
  }

  setProfileValidators() {
    this.checkedPassword1 = false;
    this.checkedPassword2 = false;

    this._userForm.controls.password1.valueChanges.subscribe((next: any) => {
      if (!this.checkedPassword1) {
        this.checkedPassword1 = true;
        this._userForm.controls.password2.updateValueAndValidity();
      }
      else {
        this.checkedPassword1 = false;
        this.checkedPassword2 = false;
      }
    });

    this._userForm.controls.password2.valueChanges.subscribe((next: any) => {
      if (!this.checkedPassword2) {
        this.checkedPassword2 = true;
        this._userForm.controls.password1.updateValueAndValidity();
      }
      else {
        this.checkedPassword1 = false;
        this.checkedPassword2 = false;
      }
    });
  }

  patch() {
    ['surname', 'name', 'email'].forEach(param => {
      this._userForm.controls[param].setValue(this.user[param]);
    });
  }

  onSubmit() {
    let user = (new FormGroupToObject()).transform(this._userForm);
    let promiseResult: any;
    let createType: boolean = true;
    if (this._userForm.value.password1 && this._userForm.value.password1 === this._userForm.value.password2)
      user['password'] = this._userForm.value.password1;
    if (this.user['id']) {
      user['id'] = this.user.id;
      promiseResult = this.userService.update(this.user['id'], user);
      createType = false;
    }
    else
      promiseResult = this.userService.create(user);
    promiseResult.then((data: User) => {
      this.user = data;
      const message: string = `Utente ${createType ? "creato" : "aggiornato"} con successo`;
      this._snackBar.open(message, 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      }).afterDismissed().subscribe(result => {
        this.authStatusService.isAuthenticated().then(valid => {
          if (!valid)
            this.router.navigate(['/']);
        });
      });
      this.patch();
    }).catch(error => {
      this._snackBar.open(`Errore ${createType ? "nella creazione" : "nell'aggiornamento"} dell'utente`, 'Chiudi', {
        direction: "ltr",
        duration: 2000,
        horizontalPosition: "center",
        politeness: "assertive",
        verticalPosition: "top"
      });
      console.log(error);
    });
  }
}
