import { Injectable } from '@angular/core';
import { ApiAuthService } from './api-auth.service';
import { Offer } from '../models/offer.schema';
import { GetAllFilter } from '../models/_to-type-orm.schema';

@Injectable({ providedIn: 'root' })
export class OfferService extends ApiAuthService {
  private readonly url = `${this.baseUrl}/offers`;

  getAll(page?: number, limit?: number, order?: {}, filter?: GetAllFilter): Promise<{ offers: Offer[]; count: number }> {
    let params = {};
    if (page) params['page'] = page;
    if (limit) params['limit'] = limit;
    if (order) params['order'] = JSON.stringify(order);
    if (filter) params['filter'] = JSON.stringify(filter);
    return this.request(() =>
      this.http.get<{ offers: Offer[]; count: number }>(`${this.url}`, { params: params, }).toPromise()
    );
  }

  getOne(id: number): Promise<Offer> {
    return this.request(() =>
      this.http.get<Offer>(`${this.url}/${id}`).toPromise()
    );
  }

  create(offerParams: Offer): Promise<Offer> {
    let body = offerParams;
    return this.request(() =>
      this.http.post<Offer>(`${this.url}`, body).toPromise()
    );
  }

  update(id: number, offerParams: Offer): Promise<Offer> {
    let body = offerParams;
    return this.request(() =>
      this.http.put<Offer>(`${this.url}/${id}`, body).toPromise()
    );
  }

  delete(id: number): Promise<{ id: number; success: boolean }> {
    return this.request(() =>
      this.http.delete<{ id: number; success: boolean }>(`${this.url}/${id}`).toPromise()
    );
  }
}
