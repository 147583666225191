import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeliveryService } from '../../services/delivery.service';
import { Delivery, DeliveryStatusAux, DeliveryStatus } from '../../models/delivery.schema';
import { AuthStatusService } from '../../services/auth-status.service';
import { MatDialog } from '@angular/material/dialog';
import { DeliveryLinkDialogComponent } from '../delivery-link-dialog/delivery-link-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';



@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.scss']
})
export class DeliveryComponent implements OnInit {
  public deliveries: Delivery[] = [];
  public groups: string[];
  public delivery: Delivery;
  public deliveryStatusAux = DeliveryStatusAux;
  public deliveryStatus = DeliveryStatus;

  constructor(
    private _snackBar: MatSnackBar,
    private authStatusService: AuthStatusService,
    private route: ActivatedRoute,
    private router: Router,
    private deliveryService: DeliveryService,
    public dialog: MatDialog,
  ) {
    try {
      this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    }
    catch {
      this.groups = [];
    }
  }

  async ngOnInit() {
    
    try {
      this.delivery = await this.deliveryService.getOne(this.route.snapshot.paramMap.get('id'));

      //if partner, set readDelivery = true
      //DG
      if (this.groups.includes('partner') && this.delivery['deluxyDelivery'] && (!this.delivery['readDelivery'])) 
      {
          let promiseResult: any;
          promiseResult = this.deliveryService.update(this.delivery['id'], {
            id: this.delivery['id'],
            readDelivery: true
            
          });      
      }


    }
    catch {
      this.router.navigateByUrl("/404", { skipLocationChange: true });
    }
  }



  public groupWhitelisted(groups: string | string[]): boolean {
    if (!Array.isArray(groups))
      return this.groups.includes(groups);
    for (let group of groups)
      if (this.groups.includes(group))
        return true;
    return false;
  }

  print(event: Event) {
    event.preventDefault();
    window.print();
  }

  async showLink(event: Event) {
    event.preventDefault();
    if (!this.delivery.identifier)
      this.delivery = await this.deliveryService.generateIdentifier(this.delivery.id);
    this.dialog.open(DeliveryLinkDialogComponent, { width: '80vw', data: { delivery: this.delivery } });
  }

  async showDdt() {
    let res = await this.deliveryService.findOneDeliveryDdt(this.delivery.id);
    let anchor = document.createElement('a');
    anchor.download = this.delivery.ddtFile.replace(/-.{4}(\..{3,4})$/, '$1');
    anchor.href = (window.webkitURL || window.URL).createObjectURL(res.body);
    anchor.dataset.downloadurl = [res.body.type, anchor.download, anchor.href].join(':');
    anchor.click();
  }

  directions(event: Event) {
    event.preventDefault();
    window.open(`https://www.google.com/maps/dir//${this.delivery.address}`, '_blank');
  }

  public async accepting(id: number) {
    if (!confirm("Accetti la nuova consegna?"))
      return;
    return this.deliveryService.setDeliveryAccepting(id).then(data => {
      // Change delivery status
      //this.deliveries.find(delivery => delivery.id == data.id).status = DeliveryStatus.accepted;
      this.snack(`Consegna aggiornata con successo`);
      window.location.reload();
    }).catch(err => {
      //alert(err);
      this.snack(`Errore nell'aggiornamento della consegna`);
      console.log(err);
    });
  }

  public async refusing(id: number) {
    if (!confirm("Rifiuti la nuova consegna?"))
      return;
    return  this.deliveryService.setDeliveryRefusing(id).then(data => {
      // Change delivery status
      //this.deliveries.find(delivery => delivery.id == data.id).status = DeliveryStatus.notAccepted;
      this.snack(`Consegna aggiornata con successo`);
      window.location.reload();
    }).catch(err => {
      //alert('');
      //alert(err);
      this.snack(`Errore nell'aggiornamento della consegna`);
      console.log(err);
    });
  }  

  snack(message: string) {
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    });
  }


}
