import { Component, OnInit } from '@angular/core';
import { FormGroupToObject } from '../../pipes/form-group-to-object.pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeliveryService } from '../../services/delivery.service';
import { Delivery, DeliveryProduct, DeliveryStatus, DeliveryStatusAux, TimePeriods } from '../../models/delivery.schema';
import { Product } from '../../models/product.schema';
import { Partner } from '../../models/partner.schema';
import { Expert } from '../../models/expert.schema';
import { PartnerService } from '../../services/partner.service';
import { ExpertService } from '../../services/expert.service';
import { AuthStatusService } from '../../services/auth-status.service';
import { ProductService } from 'src/app/services/product.service';
import { DeliveryLinkDialogComponent } from '../delivery-link-dialog/delivery-link-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { plainToClass } from 'class-transformer';
import { DatePipe } from '@angular/common';
//import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user.schema';

@Component({
  selector: 'app-delivery-form',
  templateUrl: './delivery-form.component.html',
  styleUrls: ['./delivery-form.component.scss']
})
export class DeliveryFormComponent implements OnInit {
  public groups: string[];
  public readonly formValidationMsgs;
  public _deliveryForm: any;
  public delivery: Delivery;
  public deliveryStatus = DeliveryStatus;
  public deliveryStatusAux = DeliveryStatusAux;
  public partners: Partner[];
  public experts: Expert[];
  public timePeriods = TimePeriods;
  public products: Product[];
  public removeFile: boolean;
  public tmpBtnDisabled: boolean;
  public user: User;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authStatusService: AuthStatusService,
    private _snackBar: MatSnackBar,
    private deliveryService: DeliveryService,
    private partnerService: PartnerService,
    private expertService: ExpertService,
    private productService: ProductService,
    public dialog: MatDialog,
  ) {
    this.groups = this.authStatusService.getRoles().map((elem: string) => elem.toLowerCase());
    this.formValidationMsgs = Delivery.validationMessages;
  }

  async ngOnInit() {
    await this.initialize();
  }

  async initialize() {

    
    //this.user = JSON.parse(sessionStorage.getItem("currentUser"));
    //alert(this.user.id);
    

    this.tmpBtnDisabled = false;
    this.products = (await this.productService.getAll(undefined, undefined, { 'Product.name': 'ASC' })).products;
    if (this.groups.includes('admin')) {
      try {
        this.partners = (await this.partnerService.getAll(undefined, undefined, { 'Partner.businessName': 'ASC' })).partners;
      }
      catch { }
      try {
        this.experts = (await this.expertService.getAll(undefined, undefined, { 'User.surname': 'ASC', 'User.name': 'ASC' })).experts;
      }
      catch { }
    }
    this._deliveryForm = new FormGroup(Delivery.validation());
    const deliveryId: string = this.route.snapshot.paramMap.get('id');
    if (deliveryId) {
      try {
        this.delivery = plainToClass(Delivery, await this.deliveryService.getOne(deliveryId));
      }
      catch {
        this.router.navigateByUrl("/404", { skipLocationChange: true });
      }
    }
    if (this.delivery)
      this.patch();
    else
      this.delivery = new Delivery();
  }

  async delete() {
    if (!confirm("Sei sicuro di voler cancellare questa consegna?"))
      return;
    let delivery: { id: number | string, success: boolean } = await this.deliveryService.delete(this.delivery.id);
    let message = "Cancellazione della consegna fallita.";
    if (delivery.success)
      message = "Cancellazione della consegna avvenuta con successo.";
    this._snackBar.open(message, 'Chiudi', {
      direction: "ltr",
      duration: 2000,
      horizontalPosition: "center",
      politeness: "assertive",
      verticalPosition: "top"
    }).afterDismissed().subscribe(result => {
      if (delivery.success)
        this.router.navigate(['/consegne']);
    });
  }

  patch() {
    
    this.removeFile = false;
    const control = <FormArray>this._deliveryForm.get('deliveryProducts');
    control.clear()
    if (!this.delivery.deliveryProducts || Array.isArray(this.delivery.deliveryProducts) && this.delivery.deliveryProducts.length == 0)
      this.delivery.deliveryProducts = [new DeliveryProduct()];
    this.delivery.deliveryProducts.forEach(f => {
      control.push(this.patchValues(f));
    });
    ['status', 'surname', 'name', 'address', 'intercom', 'createdUser' , 'deliveryDate', 'fromTime', 'toTime', 'notes', 'internalNotes',
      'requestExpert', 'receiverPhone', 'senderSurname', 'senderName', 'senderPhone', 'email', 'payAtDelivery', 'deluxyDelivery',
      'tryAndReturn', 'longitude', 'latitude'].forEach(param => {
        if (this.delivery[param] instanceof Date)
          this._deliveryForm.controls[param].setValue(new DatePipe('it-IT').transform(this.delivery[param], 'yyyy-MM-dd'));
        else
          this._deliveryForm.controls[param].setValue(this.delivery[param]);
      });
    if (this.delivery.partner)
      this._deliveryForm.controls.partner.controls.id.setValue(this.delivery.partner.id);
    if (this.delivery.expert)
      this._deliveryForm.controls.expert.controls.id.setValue(this.delivery.expert.id);
  }

  patchValues(deliveryProduct: DeliveryProduct) {
    let productForm = Delivery.productFormGroup();
    if (deliveryProduct?.product?.id)
      productForm.controls.product.setValue({ id: deliveryProduct.product.id });
    return productForm;
  }

  addProduct() {
    const control = <FormArray>this._deliveryForm.get('deliveryProducts');
    control.push(Delivery.productFormGroup());
  }

  removeProduct(index: number) {
    const control = <FormArray>this._deliveryForm.get('deliveryProducts');
    control.removeAt(index);
  }

  onSubmit() {
    this.tmpBtnDisabled = true;
    let delivery: Delivery = (new FormGroupToObject()).transform(this._deliveryForm);
    let ddtFile = delivery.ddtFile;
    if (!this.removeFile) delete delivery.ddtFile;
    for (let i = delivery.deliveryProducts.length - 1; i >= 0; i--) {
      if (!delivery.deliveryProducts[i].product.id)
        delivery.deliveryProducts.splice(i, 1);
    }
    let promiseResult: any;
    let createType: boolean = true;
    if (this.delivery['id']) {
      promiseResult = this.deliveryService.update(this.delivery['id'], delivery);
      createType = false;
    }
    else
      promiseResult = this.deliveryService.create(delivery);
    promiseResult.then(async (data: Delivery) => {
      let allGood = true;
      this.delivery = plainToClass(Delivery, data);
      if (ddtFile)
        this.delivery = await this.uploadDdt(this.delivery.id).catch((err) => {
          this.errorMessage(createType, err);
          allGood = false;
        });
      if (allGood) {
        const message: string = `Consegna ${createType ? "creata" : "aggiornata"} con successo. ${createType ? "Nuovo id creato : " + this.delivery.id : ""}`;
        this._snackBar.open(message, 'Chiudi', {
          direction: "ltr",
          duration: 2000,
          horizontalPosition: "center",
          politeness: "assertive",
          verticalPosition: "top"
        }).afterDismissed().subscribe(result => {
          if (createType)
            this.router.navigate(['/consegne', this.delivery.id, 'edit']);
        });
        this.patch();
        this.tmpBtnDisabled = false;
      }
    }).catch(error => {
      this.errorMessage(createType, error);
      this.tmpBtnDisabled = false;
    });
  }

  onDuplicate() {
    this.tmpBtnDisabled = true;
    let delivery: Delivery = (new FormGroupToObject()).transform(this._deliveryForm);
    let ddtFile = delivery.ddtFile;
    if (!this.removeFile) delete delivery.ddtFile;
    for (let i = delivery.deliveryProducts.length - 1; i >= 0; i--) {
      if (!delivery.deliveryProducts[i].product.id)
        delivery.deliveryProducts.splice(i, 1);
    }
    let promiseResult: any;
    let createType: boolean = true;
    //if (this.delivery['id']) {
    //  promiseResult = this.deliveryService.update(this.delivery['id'], delivery);
    //  createType = false;
    //}
    //else
    
    promiseResult = this.deliveryService.create(delivery);

    promiseResult.then(async (data: Delivery) => {
      let allGood = true;
      this.delivery = plainToClass(Delivery, data);
      if (ddtFile)
        this.delivery = await this.uploadDdt(this.delivery.id).catch((err) => {
          this.errorMessage(createType, err);
          allGood = false;
        });
      if (allGood) {
        const message: string = `Consegna duplicata con successo. Nuovo id creato : ` + this.delivery.id;
        this._snackBar.open(message, 'Chiudi', {
          direction: "ltr",
          duration: 2000,
          horizontalPosition: "center",
          politeness: "assertive",
          verticalPosition: "top"
        }).afterDismissed().subscribe(result => {
            this.router.navigate(['/consegne', this.delivery.id, 'edit']);
        });
        this.patch();
        this.tmpBtnDisabled = false;
      }
    }).catch(error => {
      //alert(error);
      console.log(error);
      this.errorMessage(createType, error);
      this.tmpBtnDisabled = false;
    });
  }  

  errorMessage(createType, error) {
    this._snackBar.open(`Errore ${createType ? 'nella creazione' : "nell'aggiornamento"} della consegna`,
      'Chiudi',
      {
        direction: 'ltr',
        duration: 2000,
        horizontalPosition: 'center',
        politeness: 'assertive',
        verticalPosition: 'top',
      }
    );
    console.log(error);
  }

  setTime(timePeriod) {

    if (this.timePeriods[timePeriod]) {
      this._deliveryForm.controls.fromTime.setValue(this.timePeriods[timePeriod]['from']);
      this._deliveryForm.controls.toTime.setValue(this.timePeriods[timePeriod]['to']);
    }
    else {
      this._deliveryForm.controls.fromTime.setValue('');
      this._deliveryForm.controls.toTime.setValue('');
    }
  }

  setDeliveryStatus(idExpert){
    //alert(idExpert);
    this._deliveryForm.controls.status.setValue(this.deliveryStatus.assigned);
    //this._deliveryForm.controls.bntSave.disabled();
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this._deliveryForm.get('ddtFile').setValue(file);
    }
  }

  uploadDdt(deliveryId: number) {
    return this.deliveryService.updateDeliveryDdt(
      deliveryId,
      this._deliveryForm.get('ddtFile').value
    );
  }

  async showDdt() {
    let res = await this.deliveryService.findOneDeliveryDdt(this.delivery.id);
    let anchor = document.createElement('a');
    anchor.download = this.delivery.ddtFile.replace(/-.{4}(\..{3,4})$/, '$1');
    anchor.href = (window.webkitURL || window.URL).createObjectURL(res.body);
    anchor.dataset.downloadurl = [res.body.type, anchor.download, anchor.href].join(':');
    anchor.click();
  }

  deleteFile() {
    this.removeFile = true;
  }

  async showLink(event: Event) {
    event.preventDefault();
    if (!this.delivery.identifier)
      this.delivery = await this.deliveryService.generateIdentifier(this.delivery.id);
    this.dialog.open(DeliveryLinkDialogComponent, { width: '80vw', data: { delivery: this.delivery } });
  }

  getAddress(place: any) {
    if (!this._deliveryForm) return;
    this._deliveryForm.controls.address.touched = true;
    this._deliveryForm.controls.address.setValue(place.formatted_address || place.name || '');
    this._deliveryForm.controls.latitude.setValue(place.geometry?.location?.lat() || '');
    this._deliveryForm.controls.longitude.setValue(place.geometry?.location?.lng() || '');
  }

  directions(event: Event) {
    event.preventDefault();
    window.open(`https://www.google.com/maps/dir//${this._deliveryForm.controls.address.value}`, '_blank');
  }
}
