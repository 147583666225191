import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CanActivateLogIn } from './helpers/guards/log-in.guard';

import { LoginComponent } from './components/auth/login/login.component';
import { RegistrationComponent } from './components/auth/registration/registration.component';
import { ResendActivationComponent } from './components/auth/resend-activation/resend-activation.component';
import { PasswordRecoveryComponent } from './components/auth/password-recovery/password-recovery.component';
import { UserLoggedIn } from './helpers/guards/logged-in.guard';
import { LogOut } from './helpers/guards/log-out.guard';

import { DeliveriesComponent } from './components/deliveries/deliveries.component';
import { DeliveryComponent } from './components/delivery/delivery.component';
import { DeliveryFormComponent } from './components/delivery-form/delivery-form.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ExpertsComponent } from './components/experts/experts.component';
import { PartnersComponent } from './components/partners/partners.component';
import { GroupRestricted } from './helpers/guards/group-restricted.guard';
import { UsersComponent } from './components/users/users.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ExpertFormDecoratorComponent } from './components/expert-form-decorator/expert-form-decorator.component';
import { PartnerFormDecoratorComponent } from './components/partner-form-decorator/partner-form-decorator.component';
import { OffersComponent } from './components/offers/offers.component';
import { OfferFormComponent } from './components/offer-form/offer-form.component';
import { ProductsComponent } from './components/products/products.component';
import { ProductFormComponent } from './components/product-form/product-form.component';
import { DeliveryCanActivateLogIn } from './helpers/guards/delivery-log-in.guard';


const routes: Routes = [
  { path: '', redirectTo: 'consegne', pathMatch: 'full' },

  { path: 'login', component: LoginComponent, canActivate: [UserLoggedIn], data: { blank: true } },
  { path: 'logout', component: LoginComponent, canActivate: [LogOut], data: { blank: true } },
  { path: 'register', component: RegistrationComponent, canActivate: [UserLoggedIn], data: { blank: true } },
  { path: 'verify/:token', component: RegistrationComponent, canActivate: [UserLoggedIn], data: { blank: true } },
  { path: 'resend_activation', component: ResendActivationComponent, canActivate: [UserLoggedIn], data: { blank: true } },
  { path: 'reset_password', redirectTo: 'reset_password/', pathMatch: 'full', data: { blank: true } },
  { path: 'reset_password/:token', component: PasswordRecoveryComponent, canActivate: [UserLoggedIn], data: { blank: true } },
  { path: 'activate/:token', component: PasswordRecoveryComponent, canActivate: [UserLoggedIn], data: { blank: true, activate: true } },

  { path: 'consegne', component: DeliveriesComponent, canActivate: [CanActivateLogIn], data: { active: true } },
  { path: 'consegne/storico', component: DeliveriesComponent, canActivate: [CanActivateLogIn], data: { active: false } },
  { path: 'consegne/nuovo', component: DeliveryFormComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'partner'] } },
  { path: 'consegne/:id', component: DeliveryComponent, canActivate: [DeliveryCanActivateLogIn] },
  { path: 'consegne/:id/edit', component: DeliveryFormComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'partner'] } },

  { path: 'offerte', component: OffersComponent, canActivate: [CanActivateLogIn], data: { active: true, groups: ['admin', 'partner'] } },
  { path: 'offerte/nuovo', component: OfferFormComponent, canActivate: [CanActivateLogIn], data: { active: true, groups: ['admin', 'partner'] } },
  { path: 'offerte/:id', component: OfferFormComponent, canActivate: [CanActivateLogIn], data: { active: true, groups: ['admin', 'partner'] } },

  { path: 'expert', component: ExpertsComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin'] } },
  { path: 'expert/nuovo/:userId', component: ExpertFormDecoratorComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin'] } },
  { path: 'expert/nuovo', component: ExpertFormDecoratorComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin'] } },
  { path: 'expert/:id/edit', component: ExpertFormDecoratorComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin'] } },

  { path: 'partner', component: PartnersComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin'] } },
  { path: 'partner/nuovo/:userId', component: PartnerFormDecoratorComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin'] } },
  { path: 'partner/nuovo', component: PartnerFormDecoratorComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin'] } },
  { path: 'partner/:id/edit', component: PartnerFormDecoratorComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin'] } },

  { path: 'prodotti', component: ProductsComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'partner'] } },
  { path: 'prodotti/nuovo', component: ProductFormComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'partner'] } },
  { path: 'prodotti/:id', component: ProductFormComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin', 'partner'] } },

  { path: 'utenti', component: UsersComponent, canActivate: [CanActivateLogIn, GroupRestricted], data: { groups: ['admin'] } },
  { path: 'profilo', component: ProfileComponent, canActivate: [CanActivateLogIn] },

  { path: '404', component: NotFoundComponent, data: { blank: true } },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
