import { Injectable } from '@angular/core';
import { LoginDTO } from '../DTO/loginDTO';
import { ApiService } from './api.service';
import { User } from '../models/user.schema';

@Injectable({ providedIn: 'root' })
export class AuthService extends ApiService {
    private readonly url = `${this.baseUrl}/auth`;

    login(loginDTO: LoginDTO) {
        let body = {
            email: loginDTO.email,
            password: loginDTO.password,
            remember: loginDTO.remember,
        };
        return this.http.post(`${this.url}/login`, body).toPromise();
    }

    newPassword(password1: string, password2: string) {
        const body = { password1: password1, password2: password2 };
        return this.http.post(`${this.url}/password/new`, body).toPromise();
    }

    resetPassword(email: string) {
        return this.http.get(`${this.url}/password/reset/${email}`).toPromise();
    }

    postNewPassword(token: string, password1: string, password2: string, activate: boolean = false) {
        const body = { password1: password1, password2: password2, activate: activate };
        return this.http.post(`${this.url}/password/reset/${token}`, body).toPromise();
    }

    activateUser(token: string): Promise<{ message: string } | User> {
        return this.http.get(`${this.url}/verify/${token}`).toPromise();
    }

    sendActivation(email: string): Promise<{ message: string }> {
        return this.http.get<{ message: string }>(`${this.url}/register/resend/${email}`).toPromise();
    }

    createUser(userData: User) {
        const body = userData;
        return this.http.post(`${this.url}/register`, body).toPromise();
    }

    getOtpSecret() {
        return this.http.get(`${this.url}/otp/secret`).toPromise();
    }

    setNewOtpSecret() {
        return this.http.put(`${this.url}/otp/secret`, {}).toPromise();
    }

    setNewBackupCodes() {
        return this.http.put(`${this.url}/otp/secret/backupCodes`, {}).toPromise();
    }

    deleteOtpSecret() {
        return this.http.delete(`${this.url}/otp/secret`).toPromise();
    }

    checkIfValid(token: string): Promise<{ message: string }> {
        return this.http.get<{ message: string }>(`${this.url}/${token}`).toPromise();
    }

    getAccessToken(refreshToken): Promise<string> {
        return this.http.post<string>(`${this.url}/token`, undefined, {
            headers: {
                Authorization: `Bearer ${refreshToken}`
            }
        }).toPromise();
    }
}
